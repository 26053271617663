import get from 'lodash/get'
import findIndex from 'lodash/findIndex'

export default function useGetConf() {
  /**
     * @desc showOptionFlagMap为联动控制 all代表全条件适合 key值为`${elementType}_${chartType}` value值为对应另外一轴(比如横轴)是什么值该轴(比如纵轴)值才还能展示的集合
     */
  const data = {
    // 纵轴
    verticalAxisDataList: [
      {
        label: '请选择',
        value: 0,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 4, 5, 6, 7, 8],
        showOptionFlagMap: 'all'
      },
      {
        label: '合同金额',
        value: 1,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [10],
          'center_1': [10],
          'right_1': [10],
          'left_2': [10],
          'center_2': [10],
          'right_2': [10],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '万元'
      },
      {
        label: '收款金额',
        value: 2,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [11],
          'center_1': [11],
          'right_1': [11],
          'left_2': [11],
          'center_2': [11],
          'right_2': [11],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '万元'
      },
      {
        label: '新增客户',
        value: 3,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [12],
          'center_1': [12],
          'right_1': [12],
          'left_2': [12],
          'center_2': [12],
          'right_2': [12],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '个'
      },
      {
        label: '新增需求',
        value: 4,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [13],
          'center_1': [13],
          'right_1': [13],
          'left_2': [13],
          'center_2': [13],
          'right_2': [13],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '个'
      },
      {
        label: '新增签单',
        value: 5,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [14],
          'center_1': [14],
          'right_1': [14],
          'left_2': [14],
          'center_2': [14],
          'right_2': [14],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '个'
      },
      {
        label: '未签需求',
        value: 6,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [13],
          'center_1': [13],
          'right_1': [13],
          'left_2': [13],
          'center_2': [13],
          'right_2': [13],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '个'
      },
      {
        label: '合同客单',
        value: 7,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [14],
          'center_1': [14],
          'right_1': [14],
          'left_2': [14],
          'center_2': [14],
          'right_2': [14],
          'left_3': [9, 18, 19, 20, 21],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '元'
      },
      {
        label: '交付客单',
        value: 8,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [15],
          'center_1': [15],
          'right_1': [15],
          'left_2': [15],
          'center_2': [15],
          'right_2': [15],
          'left_3': [9],
          'center_3': [9, 18, 19, 20, 21],
          'right_3': [9, 18, 19, 20, 21],
          'left_5': [9, 18, 19, 20, 21],
          'right_5': [9],
          'left_6': [0],
          'center_6': [0],
          'right_6': [0]
        },
        unit: '元'
      },
      {
        label: '客户来源',
        value: 9,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4],
        showOptionFlagMap: {
          'left_4': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'center_4': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'right_4': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21]
        }
      },
      {
        label: '经销商排名',
        value: 10,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [7],
        showOptionFlagMap: {
          'left_7': [1, 2, 3, 4, 5, 6, 7, 8],
          'center_7': [1, 2, 3, 4, 5, 6, 7, 8],
          'right_7': [1, 2, 3, 4, 5, 6, 7, 8]
        }
      },
      {
        label: '导购排名',
        value: 11,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [7, 8],
        showOptionFlagMap: {
          'left_7': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'center_7': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'right_7': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'left_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'center_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'right_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21]
        }
      },
      {
        label: '经销商排名',
        value: 12,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [8],
        showOptionFlagMap: {
          'left_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'center_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21],
          'right_8': [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21]
        }
      },
      {
        label: '人脉新增需求',
        value: 13,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [13],
          'center_1': [13],
          'right_1': [13],
          'left_2': [13],
          'center_2': [13],
          'right_2': [13],
          'left_3': [9],
          'center_3': [9],
          'right_3': [9],
          'left_5': [9],
          'center_5': [9],
          'right_5': [9]
        }
      },
      {
        label: '进店数',
        value: 14,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [16],
          'center_1': [16],
          'right_1': [16],
          'left_2': [16],
          'center_2': [16],
          'right_2': [16],
          'left_3': [9],
          'center_3': [9],
          'right_3': [9],
          'left_5': [9],
          'center_5': [9],
          'right_5': [9]
        }
      },
      {
        label: '方案数',
        value: 15,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [17],
          'center_1': [17],
          'right_1': [17],
          'left_2': [17],
          'center_2': [17],
          'right_2': [17],
          'left_3': [9],
          'center_3': [9],
          'right_3': [9],
          'left_5': [9],
          'center_5': [9],
          'right_5': [9]
        }
      },
      {
        label: '测量数',
        value: 16,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 5, 6],
        showOptionFlagMap: {
          'left_1': [17],
          'center_1': [17],
          'right_1': [17],
          'left_2': [17],
          'center_2': [17],
          'right_2': [17],
          'left_3': [9],
          'center_3': [9],
          'right_3': [9],
          'left_5': [9],
          'center_5': [9],
          'right_5': [9]
        }
      }
    ],
    // 横轴
    abscissaAxisDataList: [
      {
        label: '请选择',
        value: 0,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2, 3, 4, 5, 6, 7, 8],
        showOptionFlagMap: 'all'
      },
      {
        label: '合同金额',
        value: 1,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '万元'
      },
      {
        label: '收款金额',
        value: 2,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '万元'
      },
      {
        label: '新增客户',
        value: 3,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '个'
      },
      {
        label: '新增需求',
        value: 4,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '个'
      },
      {
        label: '新增签单',
        value: 5,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '个'
      },
      {
        label: '未签需求',
        value: 6,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9]
        },
        unit: '个'
      },
      {
        label: '合同客单',
        value: 7,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '元'
      },
      {
        label: '交付客单',
        value: 8,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 7, 8],
        showOptionFlagMap: {
          'left_4': [9],
          'center_4': [9],
          'right_4': [9],
          'left_7': [10, 11],
          'center_7': [10, 11],
          'right_7': [10, 11],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        },
        unit: '元'
      },
      {
        label: '客户来源',
        value: 9,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [3, 5],
        showOptionFlagMap: {
          'left_3': [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 16],
          'center_3': [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 16],
          'right_3': [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 16],
          'left_5': [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 16],
          'right_5': [1, 2, 3, 4, 5, 6, 7, 8, 13, 14, 15, 16]
        }
      },
      {
        label: '时间',
        value: 10,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [1],
          'center_1': [1],
          'right_1': [1],
          'left_2': [1],
          'center_2': [1],
          'right_2': [1]
        }
      },
      {
        label: '收款时间',
        value: 11,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [2],
          'center_1': [2],
          'right_1': [2],
          'left_2': [2],
          'center_2': [2],
          'right_2': [2]
        }
      },
      {
        label: '新增时间',
        value: 12,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [3],
          'center_1': [3],
          'right_1': [3],
          'left_2': [3],
          'center_2': [3],
          'right_2': [3]
        }
      },
      {
        label: '需求创建时间',
        value: 13,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [4, 13],
          'center_1': [4, 13],
          'right_1': [4, 13],
          'left_2': [4, 13],
          'center_2': [4, 13],
          'right_2': [4, 13]
        }
      },
      {
        label: '需求签单时间',
        value: 14,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [7],
          'center_1': [7],
          'right_1': [7],
          'left_2': [7],
          'center_2': [7],
          'right_2': [7]
        }
      },
      {
        label: '需求交付时间',
        value: 15,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [8],
          'center_1': [8],
          'right_1': [8],
          'left_2': [8],
          'center_2': [8],
          'right_2': [8]
        }
      },
      {
        label: '跟进时间',
        value: 16,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [14],
          'center_1': [14],
          'right_1': [14],
          'left_2': [14],
          'center_2': [14],
          'right_2': [14]
        }
      },
      {
        label: '服务单完成时间',
        value: 17,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [1, 2],
        showOptionFlagMap: {
          'left_1': [15, 16],
          'center_1': [15, 16],
          'right_1': [15, 16],
          'left_2': [15, 16],
          'center_2': [15, 16],
          'right_2': [15, 16]
        }
      },
      {
        label: '人脉新增需求',
        value: 18,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 8],
        showOptionFlagMap: {
          'left_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'center_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'right_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        }
      },
      {
        label: '进店数',
        value: 19,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 8],
        showOptionFlagMap: {
          'left_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'center_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'right_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        }
      },
      {
        label: '方案数',
        value: 20,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 8],
        showOptionFlagMap: {
          'left_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'center_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'right_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        }
      },
      {
        label: '测量数',
        value: 21,
        elementTypes: ['left', 'center', 'right'],
        chartTypes: [4, 8],
        showOptionFlagMap: {
          'left_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'center_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'right_4': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
          'left_8': [11, 12],
          'center_8': [11, 12],
          'right_8': [11, 12]
        }
      }
    ],
    notShowDeadlineTips: ['6_1_0', '6_2_0', '6_3_0', '6_4_0', '6_5_0', '6_6_0'], // 不显示截止日期, 格式chartType_verticalAxis_abscissaAxis
    chartTypeMap: {
      '1': '折线图',
      '2': '曲线图',
      '3': '柱状图',
      '4': '条形图',
      '5': '饼图',
      '6': '数值',
      '7': '排名图',
      '8': '排名图'
    },
    // 副指标
    secondaryIndexOptions: [
      {
        label: '合同金额',
        value: 1
      },
      {
        label: '下单金额',
        value: 2
      },
      {
        label: '收款金额',
        value: 3
      },
      {
        label: '签单数',
        value: 4
      },
      {
        label: '经销商数',
        value: 5
      },
      {
        label: '导购数',
        value: 6
      }
    ]
  }

  // 获取纵轴显示
  const getVerticalAxisShowStr = (item) => {
    const verticalAxis = get(item, 'verticalAxis', '')
    const index = findIndex(data.verticalAxisDataList, verticalAxisDataItem => verticalAxisDataItem === verticalAxis)
    if (index !== -1) {
      return data.verticalAxisDataList[index].label + (data.verticalAxisDataList[index].unit ? `(${data.verticalAxisDataList[index].unit})` : '')
    }
    return ''
  }

  // 获取横轴显示
  const getAbscissaAxisShowStr = (item) => {
    const abscissaAxis = get(item, 'abscissaAxis', '')
    const index = findIndex(data.abscissaAxisDataList, abscissaAxisDataItem => abscissaAxisDataItem === abscissaAxis)
    if (index !== -1) {
      return data.abscissaAxisDataList[index].label + (item.chartType === 4 && data.abscissaAxisDataList[index].unit ? `(${data.abscissaAxisDataList[index].unit})` : '')
    }
    return ''
  }

  // 不显示截止日期
  const getIsNotShowDeadlineTips = (item) => {
    const chartType = item.chartType
    const verticalAxis = item.verticalAxis
    const abscissaAxis = item.abscissaAxis
    const str = `${chartType}_${verticalAxis}_${abscissaAxis}`
    return data.notShowDeadlineTips.indexOf(str) === -1
  }

  // 获取副指标显示
  const getSecondaryIndexValue = (value) => {
    const index = findIndex(data.secondaryIndexOptions, secondaryIndexItem => Number(secondaryIndexItem) === Number(value))
    if (index !== -1) {
      return data.secondaryIndexOptions[index].label
    }
    return ''
  }

  return {
    ...data,
    getVerticalAxisShowStr,
    getAbscissaAxisShowStr,
    getIsNotShowDeadlineTips,
    getSecondaryIndexValue
  }
}

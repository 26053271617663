export const constantRoutes = [
  {
    path: '/pc/dataScreen', // 公司数据大屏
    component: () => import('@/views/dataScreen')
  },
  {
    path: '/pc/dataScreenShow', // 公司数据大屏展示
    component: () => import('@/views/dataScreen/show')
  },
  {
    path: '/pc/dataScreenFactory', // 厂家数据大屏
    component: () => import('@/views/dataScreenFactory')
  },
  {
    path: '/pc/dataScreenFactoryShow', // 厂家数据大屏展示
    component: () => import('@/views/dataScreenFactory/show')
  },
  // 新迁移数据大屏与原先看板分开隔离 菜单权限 25.1.9
  {
    path: '/pc/digitalWareScreenFactory', // 数据大屏新增
    component: () => import('@/views/digitalWareScreenFactory')
  },
  {
    path: '/pc/digitalWareScreenFactoryShow', // 数据大屏展示
    component: () => import('@/views/digitalWareScreenFactory/show')
  }
]

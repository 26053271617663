import get from 'lodash/get'
import { SALE_CLOUD_ID } from '@/utils/microapp-variables'

// 微前端处理用户信息
export const setMicroAppMainUserInfo = (userInfo) => {
  const userName = get(userInfo, 'userName', '') || ''
  const thumbAvatar = get(userInfo, 'thumbAvatar', '') || ''
  if (!userName) return
  const userInfoObj = {
    name: userName,
    avatar: thumbAvatar
  }
  window.$wujie?.bus?.$emit('setUserInfo', SALE_CLOUD_ID, userInfoObj)
}

/**
 * @description 数据大屏的vuex
 */
import get from 'lodash/get'
import pick from 'lodash/pick'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import take from 'lodash/take'
import { v4 as uuid } from 'uuid'
import useGetConf from '@/hocks/useGetConf'

const state = {
  fetchLoading: false,
  isShowFilterBar: true, // 是否显示过滤器
  componentListData: { // 组件数据
    left: [],
    center: [],
    right: []
  },
  attributeEditElementType: '', // 编辑组件区域类型
  attributeEditUuid: '', // 编辑的uuid
  attributeEditTimeStamp: 0, // 点击组件的时间戳
  dataSourceList: [],
  // 看板基础信息
  dataBoardBasicInfo: {
    dataBoardId: '',
    dataBoardName: '',
    template: '',
    dataSource: ''
  }
}

const mutations = {
  SET_FETCH_LOADING: (state, flag) => {
    state.fetchLoading = flag
  },
  SET_IS_SHOW_FILTER_BAR: (state, flag) => {
    state.isShowFilterBar = flag
  },
  SET_COMPONENT_LIST_DATA: (state, { key, data }) => {
    if (['left', 'center', 'right'].indexOf(key) !== -1) {
      state.componentListData[key] = take(data, 3)
    }
  },
  SET_ATTRIBUTE_EDIT_UUID: (state, uuid) => {
    state.attributeEditTimeStamp = new Date().getTime()
    state.attributeEditUuid = uuid
  },
  SET_ATTRIBUTE_EDIT_ELEMENT_TYPE: (state, elementType) => {
    state.attributeEditElementType = elementType
  },
  SET_DATA_SOURCE_LIST: (state, list) => {
    state.dataSourceList = list
  },
  SET_DATA_BOARD_BASIC_INFO: (state, obj) => {
    state.dataBoardBasicInfo = obj
  },
  SET_DATA_SOURCE: (state, name) => {
    state.dataBoardBasicInfo.dataSource = name
  },
  SET_DATA_BOARD_TEMPLATE: (state, template) => {
    state.dataBoardBasicInfo.template = template
  },
  SET_DATA_BOARD_ID: (state, id) => {
    state.dataBoardBasicInfo.dataBoardId = id
  },
  SET_DATA_BOARD_NAME: (state, name) => {
    state.dataBoardBasicInfo.dataBoardName = name
  },
  // 预设模板
  SET_DEFAULT_ALL_DATA: (state) => {
    state.isShowFilterBar = true
    state.componentListData = {
      left: [{
        _uniqId: uuid(),
        chartType: 7,
        elementName: '客户来源分析',
        abscissaAxis: 9,
        verticalAxis: 3,
        chartTypeName: '饼图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }, {
        _uniqId: uuid(),
        chartType: 9,
        elementName: '收款金额数',
        abscissaAxis: 4,
        verticalAxis: 9,
        chartTypeName: '条形图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }, {
        _uniqId: uuid(),
        chartType: 10,
        elementName: '人脉相关数据',
        abscissaAxis: 9,
        verticalAxis: 6,
        chartTypeName: '柱状图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }],
      center: [{
        _uniqId: uuid(),
        chartType: 6,
        elementName: '地图',
        abscissaAxis: 0,
        verticalAxis: 2,
        chartTypeName: '数值',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }, {
        _uniqId: uuid(),
        chartType: 1,
        elementName: '客户总数',
        abscissaAxis: 11,
        verticalAxis: 2,
        chartTypeName: '折线图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }],
      right: [{
        _uniqId: uuid(),
        chartType: 11,
        elementName: '需求总数',
        abscissaAxis: 9,
        verticalAxis: 5,
        chartTypeName: '柱状图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }, {
        _uniqId: uuid(),
        chartType: 12,
        elementName: '未签单数',
        abscissaAxis: 14,
        verticalAxis: 7,
        chartTypeName: '曲线图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }, {
        _uniqId: uuid(),
        chartType: 13,
        elementName: '人脉推荐需求数',
        abscissaAxis: 15,
        verticalAxis: 8,
        chartTypeName: '曲线图',
        elementDataDtoList: [],
        secondData: [],
        secondDataDtoList: [],
        totalNum: 0
      }]
    }
    state.attributeEditElementType = '' // 编辑组件区域类型
    state.attributeEditUuid = '' // 编辑的uuid
    state.dataSourceList = []
    // 看板基础信息
    state.dataBoardBasicInfo = {
      dataBoardId: '',
      dataBoardName: '',
      template: 1,
      dataSource: ''
    }
  },
  // 重置所有数据
  SET_RESET_ALL_DATA: (state) => {
    state.isShowFilterBar = true
    state.componentListData = { // 组件数据
      left: [],
      center: [],
      right: []
    }
    state.attributeEditElementType = '' // 编辑组件区域类型
    state.attributeEditUuid = '' // 编辑的uuid
    state.dataSourceList = []
    // 看板基础信息
    state.dataBoardBasicInfo = {
      dataBoardId: '',
      dataBoardName: '',
      template: '',
      dataSource: ''
    }
  }
}

const actions = {
  // 获取数据来源
  fetchDataSource({ commit }, root) {
    root.$request.doPost('/data-board/getDataSource', '', (res) => {
      if (res.data.code !== 0) {
        root.$alert(res.data.message, '提示')
        return
      }
      let dataSourceList = get(res, 'data.result', [])
      if (!Array.isArray(dataSourceList)) {
        dataSourceList = []
      }
      commit('SET_DATA_SOURCE_LIST', dataSourceList)
    }, (err) => {
      console.error(err)
      root.$alert('获取数据来源异常', '提示')
    }, (end) => {})
  },
  fetchBoardData({ commit }, { root, query }) {
    console.log(query, '我就是看看你是干啥的额！')
    // commit('SET_FETCH_LOADING', true)
    // commit('SET_FETCH_LOADING', false)
    let dataBoardInfoList = [
      {
        "id": 113,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "客户来源分析",
        "chartType": 8,
        "dataSource": null,
        "verticalAxis": 12,
        "abscissaAxis": 8,
        "elementType": 1,
        "elementSort": 1,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": "经销商部门-1",
            "abscissaAxis": 0
          }
        ],
        "secondDataDtoList": null,
        "totalNum": 0
      },
      {
        "id": 116,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "收款金额展示",
        "chartType": 6,
        "dataSource": null,
        "verticalAxis": 1,
        "abscissaAxis": 0,
        "elementType": 2,
        "elementSort": 1,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 5.5612,
            "abscissaAxis": null
          }
        ],
        "secondDataDtoList": [
          {
            "secondDataStr": "合同金额",
            "secondData": 1,
            "num": 5.5612
          },
          {
            "secondDataStr": "下单金额",
            "secondData": 2,
            "num": 0
          },
          {
            "secondDataStr": "收款金额",
            "secondData": 3,
            "num": 1.81
          },
          {
            "secondDataStr": "签单数",
            "secondData": 4,
            "num": 14
          },
          {
            "secondDataStr": "导购数",
            "secondData": 6,
            "num": 120
          }
        ],
        "totalNum": null
      },
      {
        "id": 118,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "需求总数",
        "chartType": 11,
        "dataSource": null,
        "verticalAxis": 16,
        "abscissaAxis": 17,
        "elementType": 3,
        "elementSort": 1,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-01"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-02"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-03"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-04"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-05"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-06"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-07"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-08"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-09"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-10"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-11"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-12"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-13"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-14"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-15"
          },
          {
            "verticalAxis": 1,
            "abscissaAxis": "2023-06-16"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-17"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-18"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-19"
          },
          {
            "verticalAxis": 1,
            "abscissaAxis": "2023-06-20"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-21"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-22"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-23"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-24"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-25"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-26"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-27"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-28"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-29"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-30"
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      },
      {
        "id": 114,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "收款金额数",
        "chartType": 9,
        "dataSource": null,
        "verticalAxis": 9,
        "abscissaAxis": 4,
        "elementType": 1,
        "elementSort": 2,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": {
              "1": "自然进店"
            },
            "abscissaAxis": 18
          },
          {
            "verticalAxis": {
              "2": "陌电开发"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "3": "小区开发"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "4": "卖场拦截"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "5": "人脉"
            },
            "abscissaAxis": 1
          },
          {
            "verticalAxis": {
              "6": "展会活动"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "7": "网络获取"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "8": "总部分配"
            },
            "abscissaAxis": 0
          },
          {
            "verticalAxis": {
              "9": "其他"
            },
            "abscissaAxis": 4
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      },
      {
        "id": 117,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "客户总数",
        "chartType": 1,
        "dataSource": null,
        "verticalAxis": 1,
        "abscissaAxis": 10,
        "elementType": 2,
        "elementSort": 2,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-01"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-02"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-03"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-04"
          },
          {
            "verticalAxis": 1.1,
            "abscissaAxis": "2023-06-05"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-06"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-07"
          },
          {
            "verticalAxis": 1.1612,
            "abscissaAxis": "2023-06-08"
          },
          {
            "verticalAxis": 0.5,
            "abscissaAxis": "2023-06-09"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-10"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-11"
          },
          {
            "verticalAxis": 0.5,
            "abscissaAxis": "2023-06-12"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-13"
          },
          {
            "verticalAxis": 0.2,
            "abscissaAxis": "2023-06-14"
          },
          {
            "verticalAxis": 2,
            "abscissaAxis": "2023-06-15"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-16"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-17"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-18"
          },
          {
            "verticalAxis": 0.1,
            "abscissaAxis": "2023-06-19"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-20"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-21"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-22"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-23"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-24"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-25"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-26"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-27"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-28"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-29"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-30"
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      },
      {
        "id": 119,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "未签单数",
        "chartType": 12,
        "dataSource": null,
        "verticalAxis": 7,
        "abscissaAxis": 14,
        "elementType": 3,
        "elementSort": 2,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-01"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-02"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-03"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-04"
          },
          {
            "verticalAxis": 3666.67,
            "abscissaAxis": "2023-06-05"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-06"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-07"
          },
          {
            "verticalAxis": 1935.33,
            "abscissaAxis": "2023-06-08"
          },
          {
            "verticalAxis": 5000,
            "abscissaAxis": "2023-06-09"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-10"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-11"
          },
          {
            "verticalAxis": 5000,
            "abscissaAxis": "2023-06-12"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-13"
          },
          {
            "verticalAxis": 2000,
            "abscissaAxis": "2023-06-14"
          },
          {
            "verticalAxis": 20000,
            "abscissaAxis": "2023-06-15"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-16"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-17"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-18"
          },
          {
            "verticalAxis": 1000,
            "abscissaAxis": "2023-06-19"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-20"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-21"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-22"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-23"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-24"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-25"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-26"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-27"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-28"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-29"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-30"
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      },
      {
        "id": 115,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "人脉相关数据",
        "chartType": 10,
        "dataSource": null,
        "verticalAxis": 6,
        "abscissaAxis": 9,
        "elementType": 1,
        "elementSort": 3,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 7,
            "abscissaAxis": {
              "1": "自然进店"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "2": "陌电开发"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "3": "小区开发"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "4": "卖场拦截"
            }
          },
          {
            "verticalAxis": 1,
            "abscissaAxis": {
              "5": "人脉"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "6": "展会活动"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "7": "网络获取"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "8": "总部分配"
            }
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": {
              "9": "其他"
            }
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      },
      {
        "id": 120,
        "authcorpid": "ww3f966d35809e93bc",
        "dataBoardId": 7,
        "elementName": "人脉推荐需求数",
        "chartType": 13,
        "dataSource": null,
        "verticalAxis": 8,
        "abscissaAxis": 15,
        "elementType": 3,
        "elementSort": 3,
        "createTime": "2023-06-14T19:43:57",
        "createUser": "ZhangMeng",
        "elementDataDtoList": [
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-01"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-02"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-03"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-04"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-05"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-06"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-07"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-08"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-09"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-10"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-11"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-12"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-13"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-14"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-15"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-16"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-17"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-18"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-19"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-20"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-21"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-22"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-23"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-24"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-25"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-26"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-27"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-28"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-29"
          },
          {
            "verticalAxis": 0,
            "abscissaAxis": "2023-06-30"
          }
        ],
        "secondDataDtoList": null,
        "totalNum": null
      }
    ]
    const dataBoardBasicInfo = {
      dataBoardId: query.id,
      dataBoardName: query.name,
      template: query.template,
      dataSource: query.dataSource
    }
    if (!Array.isArray(dataBoardInfoList)) {
      dataBoardInfoList = []
    }
    const { chartTypeMap } = useGetConf()
    const leftComponentListData = dataBoardInfoList.filter(item => item.elementType === 1)
    const centerComponentListData = dataBoardInfoList.filter(item => item.elementType === 2)
    const rightComponentListData = dataBoardInfoList.filter(item => item.elementType === 3)
    const getFixListData = (list) => {
      return list.map(item => {
        const obj = pick(item, ['chartType', 'elementName', 'abscissaAxis', 'verticalAxis', 'secondDataDtoList', 'totalNum'])
        obj.chartTypeName = get(chartTypeMap, item.chartType, '')
        let elementDataDtoList = item.elementDataDtoList
        if (!Array.isArray(elementDataDtoList)) {
          elementDataDtoList = []
        }
        obj.elementDataDtoList = elementDataDtoList
        let secondDataDtoList = item.secondDataDtoList
        if (!Array.isArray(secondDataDtoList)) {
          secondDataDtoList = []
        }
        obj.secondDataDtoList = secondDataDtoList
        // 排名面板特殊字段
        if (obj.chartType === 7) {
          obj.totalNum = item.totalNum || 0
        }
        obj.secondData = secondDataDtoList.map(item => item.secondData)
        return {
          _uniqId: uuid(),
          ...obj
        }
      })
    }
    const leftComponentListDataFix = getFixListData(leftComponentListData)
    const centerComponentListDataFix = getFixListData(centerComponentListData)
    const rightComponentListDataFix = getFixListData(rightComponentListData)
      if (dataBoardBasicInfo.dataSource) {
        dataBoardBasicInfo.dataSource && commit('SET_DATA_BOARD_BASIC_INFO', dataBoardBasicInfo) // 有数据来源再清空数据看板基础信息
      } else {
        commit('SET_DATA_BOARD_BASIC_INFO', dataBoardBasicInfo)
      }
    commit('SET_COMPONENT_LIST_DATA', { key: 'left', data: leftComponentListDataFix })
    commit('SET_COMPONENT_LIST_DATA', { key: 'center', data: centerComponentListDataFix })
    commit('SET_COMPONENT_LIST_DATA', { key: 'right', data: rightComponentListDataFix })
    commit('SET_ATTRIBUTE_EDIT_UUID', '')
    commit('SET_ATTRIBUTE_EDIT_ELEMENT_TYPE', '')
    // root.$request.doPost('/data-board/getDataBoard', query, (res) => {
    //   if (res.data.code !== 0) {
    //     root.$alert(res.data.message, '提示')
    //     return
    //   }
    //   const dataBoardBasicInfo = {
    //     dataBoardId: query.id,
    //     dataBoardName: get(res, 'data.result.dataBoard.dataBoardName', ''),
    //     template: get(res, 'data.result.dataBoard.template', 1),
    //     dataSource: get(res, 'data.result.dataBoard.dataSource', '')
    //   }
    //   const isShowFilterBar = !get(res, 'data.result.dataBoard.hideFlag', 0)
    //   let dataBoardInfoList = get(res, 'data.result.dataBoardInfoList', [])
    //   if (!Array.isArray(dataBoardInfoList)) {
    //     dataBoardInfoList = []
    //   }
    //   const { chartTypeMap } = useGetConf()
    //   const leftComponentListData = dataBoardInfoList.filter(item => item.elementType === 1)
    //   const centerComponentListData = dataBoardInfoList.filter(item => item.elementType === 2)
    //   const rightComponentListData = dataBoardInfoList.filter(item => item.elementType === 3)
    //   const getFixListData = (list) => {
    //     return list.map(item => {
    //       const obj = pick(item, ['chartType', 'elementName', 'abscissaAxis', 'verticalAxis', 'secondDataDtoList', 'totalNum'])
    //       obj.chartTypeName = get(chartTypeMap, item.chartType, '')
    //       let elementDataDtoList = item.elementDataDtoList
    //       if (!Array.isArray(elementDataDtoList)) {
    //         elementDataDtoList = []
    //       }
    //       obj.elementDataDtoList = elementDataDtoList
    //       let secondDataDtoList = item.secondDataDtoList
    //       if (!Array.isArray(secondDataDtoList)) {
    //         secondDataDtoList = []
    //       }
    //       obj.secondDataDtoList = secondDataDtoList
    //       // 排名面板特殊字段
    //       if (obj.chartType === 7) {
    //         obj.totalNum = item.totalNum || 0
    //       }
    //       obj.secondData = secondDataDtoList.map(item => item.secondData)
    //       return {
    //         _uniqId: uuid(),
    //         ...obj
    //       }
    //     })
    //   }
    //   const leftComponentListDataFix = getFixListData(leftComponentListData)
    //   const centerComponentListDataFix = getFixListData(centerComponentListData)
    //   const rightComponentListDataFix = getFixListData(rightComponentListData)
    //   if (dataBoardBasicInfo.dataSource) {
    //     dataBoardBasicInfo.dataSource && commit('SET_DATA_BOARD_BASIC_INFO', dataBoardBasicInfo) // 有数据来源再清空数据看板基础信息
    //   } else {
    //     commit('SET_DATA_BOARD_BASIC_INFO', dataBoardBasicInfo)
    //   }
    //   commit('SET_COMPONENT_LIST_DATA', { key: 'left', data: leftComponentListDataFix })
    //   commit('SET_COMPONENT_LIST_DATA', { key: 'center', data: centerComponentListDataFix })
    //   commit('SET_COMPONENT_LIST_DATA', { key: 'right', data: rightComponentListDataFix })
    //   commit('SET_ATTRIBUTE_EDIT_UUID', '')
    //   commit('SET_ATTRIBUTE_EDIT_ELEMENT_TYPE', '')
    //   commit('SET_IS_SHOW_FILTER_BAR', isShowFilterBar)
    // }, (err) => {
    //   console.error(err)
    //   root.$alert('获取看板数据异常', '提示')
    // }, (end) => {})
  },
  // 点击删除组件
  delComponent({ commit, state }) {
    const attributeEditUuid = state.attributeEditUuid
    const attributeEditElementType = state.attributeEditElementType
    const componentListData = state.componentListData
    const componentListDataCopy = cloneDeep(componentListData[attributeEditElementType])
    const index = findIndex(componentListData[attributeEditElementType], item => item._uniqId === attributeEditUuid)
    if (index !== -1) {
      componentListDataCopy.splice(index, 1)
      commit('SET_ATTRIBUTE_EDIT_UUID', '')
      commit('SET_COMPONENT_LIST_DATA', { key: attributeEditElementType, data: componentListDataCopy })
    }
  }
}

const getters = {
  // 数据来源名称
  dataSourceName: state => {
    const index = findIndex(state.dataSourceList, item => item.id === state.dataBoardBasicInfo.dataSource)
    return index === -1 ? '' : state.dataSourceList[index].departmentName
  },
  // 提交数据
  submitData: state => {
    const dataBoardInfoReqList = []
    const setDataBoardInfoReqItem = (list, elementType) => {
      list.forEach((item, index) => {
        const obj1 = pick(item, ['chartType', 'elementName', 'abscissaAxis', 'verticalAxis', 'secondData'])
        // 排名组件增加字段sortNum
        if (obj1.chartType === 7) {
          obj1.sortNum = 10
        }
        dataBoardInfoReqList.push({
          ...obj1,
          dataBoardId: state.dataBoardBasicInfo.dataBoardId,
          dataSource: state.dataBoardBasicInfo.dataSource,
          elementSort: index + 1,
          elementType
        })
      })
    }
    console.log(state.componentListData, '看看数据怎么样，到底是什么')
    setDataBoardInfoReqItem(state.componentListData.left, 1)
    setDataBoardInfoReqItem(state.componentListData.center, 2)
    setDataBoardInfoReqItem(state.componentListData.right, 3)
    return {
      dataBoardInfoReqList,
      dataBoardName: state.dataBoardBasicInfo.dataBoardName,
      template: state.dataBoardBasicInfo.template,
      hideFlag: state.isShowFilterBar ? 0 : 1
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

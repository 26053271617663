<template>
  <div id="app" v-loading="appLoading">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      appLoading:false
    }
  },
  methods: {
    getLogo(){
      let that=this;
      let call=()=>{
        that.appLoading=true;
        that.$request.doPost("/user/getUpCorpidInfo",{},(res) => {
          if (res.data.code != 0) {
            return;
          }
          let corpid=res.data.result;
          let logoStr=process.env.VUE_APP_XSY_LOGO_JSON;
          let logoObj=eval('('+logoStr+')');
          let logo=logoObj[corpid];
          let logoArr=logo?logo.split('/'):[];
          let logoText=logoArr.length>0?logoArr[logoArr.length-1]:'';
          let nameStr=process.env.VUE_APP_XSY_NAME_JSON;
          let nameObj=eval('('+nameStr+')');
          let name=nameObj[corpid];
          that.$store.state.xsyLogo = logoText?require('@/assets/img/logo/'+logoText):require(process.env.VUE_APP_XSY_LOGO);
          that.$store.state.xsyName = name?name:process.env.VUE_APP_XSY_NAME;
          let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          link.href = that.$store.state.xsyLogo;
          document.getElementsByTagName('head')[0].appendChild(link);
          document.title=that.$store.state.xsyName;
        },(err) => {},(end) => {
          that.appLoading=false;
        });	
      }
      let userInfo = sessionStorage.getItem('userInfo');
      let token=userInfo == null ? '' : JSON.parse(userInfo).token;
      if(token){
        call()
      }else{
        setTimeout(()=>{
          that.getLogo()
        },100)
      }
    }
  },
  created() {
    if(process.env.VUE_APP_IS_ZIJIAN==1&&process.env.VUE_APP_XSY_LOGO_JSON!=undefined&&process.env.VUE_APP_XSY_LOGO_JSON!='{}'){
      this.getLogo();
    }else{
      this.$store.state.xsyLogo = require(process.env.VUE_APP_XSY_LOGO);
      this.$store.state.xsyName = process.env.VUE_APP_XSY_NAME;
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.$store.state.xsyLogo;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title=this.$store.state.xsyName;
    }
  }
};
</script>

<style lang="scss">
@import './style/layout.scss';

* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  color: #5a5a5a;
  background-color: #f0f2f5;
  --mainBlue: #409eff;
  --bgGray: #fafafa;
  --bordGray: #e9e9e9;
  --fontBlack: #5a5a5a;
  --fontGray: #8d8d8d;
  // 解决下拉框定位错误问题
  position: relative;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

/* 通用样式 */
.c-pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}

.c-drawer .rtl {
  width: 25rem !important;
}

.c-drawer .rtl > .el-drawer__header {
  padding: 0;
  margin: 0;
  height: 3.125rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--bordGray);
  padding: 0 1.25rem;
}

.c-drawer .rtl > .el-drawer__body {
  box-sizing: border-box;
  padding: 0 1.25rem;
  overflow-y: auto;
}

.c-dialog > .el-dialog > .el-dialog__header {
  box-sizing: border-box;
  border-bottom: 1px solid var(--bordGray);
}

.el-table__body {
  width: 100%;
  table-layout: fixed !important;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* 无下拉框 */
.no-overflow {
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none;
}

.no-overflow::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.no-overflow .scroll_content .-o-scrollbar {
  -moz-appearance: none !important;
  background: rgba(0, 255, 0, 0) !important;
}

/* 显示部分下拉框 */
.form-overflow::-webkit-scrollbar {
  width: 6px;
  height: 100%;
}

.form-overflow::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.form-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.online-overflow>ww-open-data>iframe>html>body,
.online-overflow {
  max-width: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.online-overflow-line {
  width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto-overflow>ww-open-data>iframe>html>body,
.auto-overflow {
  width: 140px;
  display: inline-block;
}

// 富文本编辑器图片
.img-editor{
  vertical-align: top;
}

.text-primary {
  color: #409EFF !important
}

.text-success {
  color: #67C23A !important;
}

.text-danger {
  color: #F56C6C !important;
}

.text-warning {
  color: #E6A23C !important;
}

// 仿下拉框
.c-imitate-inp{
  width: 220px;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 36px 0 15px;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}
.c-imitate-inp:hover{
  border-color: #C0C4CC;
}
.c-imitate-inp>.icon-arrow{
  color: #C0C4CC;
  position: absolute;
  top: 13px;
  right: 11px;
  z-index: auto;
}
.c-imitate-inp>.placeholder{
  color: #C0C4CC;
}
.c-imitate-inp-small{
  height: 32px;
  line-height: 32px;
  font-size: 13px;
}
.c-imitate-inp-small>.icon-arrow{
  top: 9.5px;
}
/* 弹窗 */
.c-pop-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.c-pop-hide{
	position: fixed;
	background-color: #FFFFFF;
	transition: all 0.3s ease;
	opacity: 0;
	z-index: 101;
}
.c-pop-right-hide{
	@extend .c-pop-hide;
  width: 90%;
  height: 100vh;
  top:0;
  right:0;
  transform: translateX(100%);
}
.c-pop-bottom-hide{
	@extend .c-pop-hide;
  width: 100vw;
  height: auto;
	max-height: 80vh;
  bottom:0;
  left:0;
  transform: translateY(100%);
}
.c-pop-center-hide{
	@extend .c-pop-hide;
  width: 50vw;
  height: 50vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: scale(0);
}
.c-pop-show{
	transition: all 0.3s ease;
	opacity: 1;
}
.c-pop-around-show{
	@extend .c-pop-show;
  transform: translateX(0);
}
.c-pop-center-show{
	@extend .c-pop-show;
  transform: scale(1);
}
// 级联多选只能选最后一级
.c-special-multiple-cascader .el-cascader-panel li[aria-haspopup] .el-checkbox{
  display:none;
}
</style>

/**
 * @description 数据大屏的vuex
 */
import get from 'lodash/get'
import pick from 'lodash/pick'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuid } from 'uuid'
import useGetConf from '@/hocks/useGetConf'

const state = {
  isShowFilterBar: true, // 是否显示过滤器
  componentListData: { // 组件数据
    left: [],
    center: [],
    right: []
  },
  attributeEditElementType: '', // 编辑组件区域类型
  attributeEditUuid: '', // 编辑的uuid
  attributeEditTimeStamp: 0, // 点击组件的时间戳
  dataSourceList: [],
  // 看板基础信息
  dataBoardBasicInfo: {
    dataBoardId: '',
    dataBoardName: '',
    template: '',
    dataSource: ''
  }
}

const mutations = {
  SET_IS_SHOW_FILTER_BAR: (state, flag) => {
    state.isShowFilterBar = flag
  },
  SET_COMPONENT_LIST_DATA: (state, { key, data }) => {
    if (['left', 'center', 'right'].indexOf(key) !== -1) {
      state.componentListData[key] = data
    }
  },
  SET_ATTRIBUTE_EDIT_UUID: (state, uuid) => {
    state.attributeEditTimeStamp = new Date().getTime()
    state.attributeEditUuid = uuid
  },
  SET_ATTRIBUTE_EDIT_ELEMENT_TYPE: (state, elementType) => {
    state.attributeEditElementType = elementType
  },
  SET_DATA_SOURCE_LIST: (state, list) => {
    state.dataSourceList = list
  },
  SET_DATA_BOARD_BASIC_INFO: (state, obj) => {
    state.dataBoardBasicInfo = obj
  },
  SET_DATA_SOURCE: (state, name) => {
    state.dataBoardBasicInfo.dataSource = name
  },
  SET_DATA_BOARD_TEMPLATE: (state, template) => {
    state.dataBoardBasicInfo.template = template
  },
  SET_DATA_BOARD_ID: (state, id) => {
    state.dataBoardBasicInfo.dataBoardId = id
  },
  SET_DATA_BOARD_NAME: (state, name) => {
    state.dataBoardBasicInfo.dataBoardName = name
  },
  // 预设模板
  SET_DEFAULT_ALL_DATA: (state) => {
    state.isShowFilterBar = true
    state.componentListData = {
      left: [{
        _uniqId: uuid(),
        chartType: 5,
        elementName: '线索来源占比',
        abscissaAxis: 9,
        verticalAxis: 3,
        chartTypeName: '饼图',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 4,
        elementName: '各来源需求新增分析',
        abscissaAxis: 4,
        verticalAxis: 9,
        chartTypeName: '条形图',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 3,
        elementName: '未签需求分析',
        abscissaAxis: 9,
        verticalAxis: 6,
        chartTypeName: '柱状图',
        elementDataDtoList: []
      }],
      center: [{
        _uniqId: uuid(),
        chartType: 6,
        elementName: '收款金额展示',
        abscissaAxis: 0,
        verticalAxis: 2,
        chartTypeName: '数值',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 1,
        elementName: '合同金额趋势图',
        abscissaAxis: 10,
        verticalAxis: 1,
        chartTypeName: '折线图',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 1,
        elementName: '收款金额趋势图',
        abscissaAxis: 11,
        verticalAxis: 2,
        chartTypeName: '折线图',
        elementDataDtoList: []
      }],
      right: [{
        _uniqId: uuid(),
        chartType: 3,
        elementName: '签单需求',
        abscissaAxis: 9,
        verticalAxis: 6,
        chartTypeName: '柱状图',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 2,
        elementName: '合同客单趋势图',
        abscissaAxis: 14,
        verticalAxis: 7,
        chartTypeName: '曲线图',
        elementDataDtoList: []
      }, {
        _uniqId: uuid(),
        chartType: 2,
        elementName: '交付客单趋势图',
        abscissaAxis: 15,
        verticalAxis: 8,
        chartTypeName: '曲线图',
        elementDataDtoList: []
      }]
    }
    state.attributeEditElementType = '' // 编辑组件区域类型
    state.attributeEditUuid = '' // 编辑的uuid
    state.dataSourceList = []
    // 看板基础信息
    state.dataBoardBasicInfo = {
      dataBoardId: '',
      dataBoardName: '',
      template: 1,
      dataSource: ''
    }
  },
  // 重置所有数据
  SET_RESET_ALL_DATA: (state) => {
    state.isShowFilterBar = true
    state.componentListData = { // 组件数据
      left: [],
      center: [],
      right: []
    }
    state.attributeEditElementType = '' // 编辑组件区域类型
    state.attributeEditUuid = '' // 编辑的uuid
    state.dataSourceList = []
    // 看板基础信息
    state.dataBoardBasicInfo = {
      dataBoardId: '',
      dataBoardName: '',
      template: '',
      dataSource: ''
    }
  }
}

const actions = {
  // 获取数据来源
  fetchDataSource({ commit }, root) {
    root.$request.doPost('/data-board/getDataSource', '', (res) => {
      if (res.data.code !== 0) {
        root.$alert(res.data.message, '提示')
        return
      }
      let dataSourceList = get(res, 'data.result', [])
      if (!Array.isArray(dataSourceList)) {
        dataSourceList = []
      }
      commit('SET_DATA_SOURCE_LIST', dataSourceList)
    }, (err) => {
      console.error(err)
      root.$alert('获取数据来源异常', '提示')
    }, (end) => {})
  },
  fetchBoardData({ commit }, { root, query }) {
    root.$request.doPost('/data-board/getDataBoard', query, (res) => {
      if (res.data.code !== 0) {
        root.$alert(res.data.message, '提示')
        return
      }
      const dataBoardBasicInfo = {
        dataBoardId: query.id,
        dataBoardName: get(res, 'data.result.dataBoard.dataBoardName', ''),
        template: get(res, 'data.result.dataBoard.template', 1),
        dataSource: get(res, 'data.result.dataBoard.dataSource', '')
      }
      let dataBoardInfoList = get(res, 'data.result.dataBoardInfoList', [])
      if (!Array.isArray(dataBoardInfoList)) {
        dataBoardInfoList = []
      }
      const { chartTypeMap } = useGetConf()
      const leftComponentListData = dataBoardInfoList.filter(item => item.elementType === 1)
      const centerComponentListData = dataBoardInfoList.filter(item => item.elementType === 2)
      const rightComponentListData = dataBoardInfoList.filter(item => item.elementType === 3)
      const getFixListData = (list) => {
        return list.map(item => {
          const obj = pick(item, ['chartType', 'elementName', 'abscissaAxis', 'verticalAxis'])
          obj.chartTypeName = get(chartTypeMap, item.chartType, '')
          let elementDataDtoList = item.elementDataDtoList
          if (!Array.isArray(elementDataDtoList)) {
            elementDataDtoList = []
          }
          obj.elementDataDtoList = elementDataDtoList
          return {
            _uniqId: uuid(),
            ...obj
          }
        })
      }
      const leftComponentListDataFix = getFixListData(leftComponentListData)
      const centerComponentListDataFix = getFixListData(centerComponentListData)
      const rightComponentListDataFix = getFixListData(rightComponentListData)

      dataBoardBasicInfo.dataSource && commit('SET_DATA_BOARD_BASIC_INFO', dataBoardBasicInfo) // 有数据来源再清空数据看板基础信息
      commit('SET_COMPONENT_LIST_DATA', { key: 'left', data: leftComponentListDataFix })
      commit('SET_COMPONENT_LIST_DATA', { key: 'center', data: centerComponentListDataFix })
      commit('SET_COMPONENT_LIST_DATA', { key: 'right', data: rightComponentListDataFix })
      commit('SET_ATTRIBUTE_EDIT_UUID', '')
      commit('SET_ATTRIBUTE_EDIT_ELEMENT_TYPE', '')
    }, (err) => {
      console.error(err)
      root.$alert('获取看板数据异常', '提示')
    }, (end) => {})
  },
  // 点击删除组件
  delComponent({ commit, state }) {
    const attributeEditUuid = state.attributeEditUuid
    const attributeEditElementType = state.attributeEditElementType
    const componentListData = state.componentListData
    const componentListDataCopy = cloneDeep(componentListData[attributeEditElementType])
    const index = findIndex(componentListData[attributeEditElementType], item => item._uniqId === attributeEditUuid)
    if (index !== -1) {
      componentListDataCopy.splice(index, 1)
      commit('SET_ATTRIBUTE_EDIT_UUID', '')
      commit('SET_COMPONENT_LIST_DATA', { key: attributeEditElementType, data: componentListDataCopy })
    }
  }
}

const getters = {
  // 数据来源名称
  dataSourceName: state => {
    const index = findIndex(state.dataSourceList, item => item.id === state.dataBoardBasicInfo.dataSource)
    return index === -1 ? '' : state.dataSourceList[index].departmentName
  },
  // 提交数据
  submitData: state => {
    const dataBoardInfoReqList = []
    const setDataBoardInfoReqItem = (list, elementType) => {
      list.forEach((item, index) => {
        const obj1 = pick(item, ['chartType', 'elementName', 'abscissaAxis', 'verticalAxis'])
        dataBoardInfoReqList.push({
          ...obj1,
          dataBoardId: state.dataBoardBasicInfo.dataBoardId,
          dataSource: state.dataBoardBasicInfo.dataSource,
          elementSort: index + 1,
          elementType
        })
      })
    }
    setDataBoardInfoReqItem(state.componentListData.left, 1)
    setDataBoardInfoReqItem(state.componentListData.center, 2)
    setDataBoardInfoReqItem(state.componentListData.right, 3)
    return {
      dataBoardInfoReqList,
      dataBoardName: state.dataBoardBasicInfo.dataBoardName,
      template: state.dataBoardBasicInfo.template
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

<template>
  <div id="hoverWindow" v-if="staffFlag" :style="'top:' + clientY + 'px;'">
    <div v-for="(item, index) in list" class="hoverC" :key="index">
      <div v-if="item.isShow">
        <div class="hoverC-1">{{ item.title }}</div>
        <div class="hoverC-2"></div>
        <div class="hoverC-3">
          <div class="hoverC-4" v-for="(ite, inde) in item.value" :key="inde">
            <div v-if="ite.isShow" @click="toPage(ite)">
              {{ ite.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hoverWindow",
  props: {
    breadLis: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      staffFlag: false,
      clientY: 0,
      list: [],
      breadList: this.breadLis,
    };
  },
  mounted() {
    //this.$emit("getSelUrl", this.breadList);
  },
  created() {
    // this.breadList =
    //   JSON.parse(localStorage.getItem("breadList")) == null
    //     ? []
    //     : JSON.parse(localStorage.getItem("breadList"));
  },
  methods: {
    show(clientY, list) {
      this.staffFlag = true;
      this.clientY = clientY;
      this.list = list;
    },
    toPage(val) {
      var ite = {
        name: val.name,
        path: val.path,
        selected: true,
        fullPath: val.path,
      };
      if (this.breadList.length == 0) {
        this.breadList.push(ite);
      } else {
        this.breadList.forEach((item) => {
          item.selected = false;
          if (this.breadList.findIndex((aa) => aa.path == ite.path) == -1) {
            this.breadList.push(ite);
          } else {
            if (item.path == ite.path) {
              item.selected = true;
              item.fullPath = val.path;
            }
          }
        });
      }
      this.$router.push({
        name: val.name,
        path: val.path,
        fullPath: val.path,
        selected: true,
      });
      this.staffFlag = false;
      this.$emit("getSelUrl", this.breadList);
      return;
      if (val.path != "" && val.path != url) {
        this.$router.push({
          path: val.path,
          meta: { keepAlive: false },
          fullPath: val.path,
          query: {},
        });
        this.staffFlag = false;
      }
    },
    cancel() {
      this.staffFlag = false;
    },
  },
};
</script>

<style scoped>
#hoverWindow {
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: #01152a;
  z-index: 9999;
  left: 200px;
  height: auto;
  font-size: 14px;
}
#hoverWindow > .hoverC {
  color: #fff;
  padding: 5px 20px;
  /* border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid; */
}
#hoverWindow > .hoverC >>> .hoverC-1 {
  margin: 5px;
}
#hoverWindow > .hoverC >>> .hoverC-2 {
  background-color: gray;
  height: 1px;
}
#hoverWindow > .hoverC >>> .hoverC-3 > .hoverC-4 {
  cursor: pointer;
  margin: 15px 5px;
}
#hoverWindow > .hoverC >>> .hoverC-3 > .hoverC-4:hover {
  color: #fe733d;
}
</style>
let regMobile = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;  
let regPhone = /^1[3456789]\d{9}$/;
let regBlank = /^\s$/;
let regNumber = /^\d+$/;
let regMoney = /^\d+(\.\d+)?$/;
let regAge = /^([0-9]{1}|[1-9]{1}[0-9]{1}|1[0-9]{1}[0-9]{1}|200)$/;
let regRateINT = /^([1-9]{1}|[1-9]{1}[0-9]{1}|100)$/;
let regFrontINI = /^[1-9]\d*$/;

module.exports = {
	regMobile,
	regPhone,
	regBlank,
	regNumber,	
	regMoney,
	regAge,
	regRateINT,
	regFrontINI,
}

// 时间格式转换
let getDT = (dt, type) => {
	let result = '';
	let date =dt==null?new Date():new Date(dt);
	let year = date.getFullYear();
	let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	if (type == 'date') {
		result = year + '-' + month + '-' + day;
	} else if (type == 'time') {
		result = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
	}
	return result;
}

export default {
	getDT,
}

<template>
  <el-container id="face" v-loading="loadFlag" :class="{'microapp-container': isInWujie}">
    <el-container>
      <div v-show="!isInWujie" class="el-container-left" :style="{ width: isCollapse ? '70px' : '200px' }">
        <el-header>
          <div id="faceLogo" :style="{ width: isCollapse ? '30px' : '200px','padding-left': isCollapse ? '10px' : '25px'}">
            <img :src="$store.state.xsyLogo" alt="">
            <span v-show="!isCollapse">{{ $store.state.xsyName }}</span>
          </div>
          <div id="faceFold">
            <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="handleMenuCollapse" />
          </div>
        </el-header>
        <el-aside class="no-overflow" :width="isCollapse ? '70px' : '200px'">
          <el-menu mode="vertical" unique-opened router :default-active="vertical" :collapse="isCollapse" :collapse-transition="false" @select="handelselect">
            <el-menu-item index="/pc/index">
              <img src="@/assets/img/el-icon-house.svg" class="item-icon" alt="">
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item v-if="isDbnum" index="/pc/auditList">
              <i class="el-icon-aim" />
              <span slot="title">审核代办<span class="dbnum">{{ dbnum }}</span></span>
            </el-menu-item>
            <el-submenu v-if="authList[0] || authList[5] || authList[11] || authList[34] || authList[62] || jxcRoleList[24] || (authList[12]&&userInfo.agentid!=-1)" index="/pc/customerList">
              <template slot="title">
                <img src="@/assets/img/el-icon-user.svg" class="item-icon" alt="">
                <span>客户管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[0]" index="/pc/customerList">
                  客户列表
                </el-menu-item>
                <el-menu-item v-if="authList[34]" index="" @click="openColl">
                  收款明细
                </el-menu-item>
                <el-menu-item v-if="authList[5]" index="/pc/demandList">
                  需求列表
                </el-menu-item>
                <el-menu-item v-if="authList[62]" index="/pc/signList">
                  签单列表
                </el-menu-item>
                <el-menu-item v-if="authList[11]" index="/pc/seaList">
                  客户公海
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[24]" index="/pc/customerAssociated">
                  客户联查表
                </el-menu-item>
                <el-menu-item v-if="authList[12]&&userInfo.agentid!=-1" index="/pc/customerBase">
                  客户群
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="authList[6] || authList[28] || authList[33] || authList[37] || authList[49] || authList[68]" index="/pc/introduceControl">
              <template slot="title">
                <img src="@/assets/img/el-icon-postcard.svg" class="item-icon" alt="">
                <span>人脉管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[6]" index="/pc/introduceControl">
                  人脉管理
                </el-menu-item>
                <el-menu-item v-if="authList[28]" index="/pc/introduceClue">
                  人脉线索管理
                </el-menu-item>
                <el-menu-item v-if="authList[33]" index="/pc/introduceCompany">
                  人脉公司管理
                </el-menu-item>
                <el-menu-item v-if="authList[68]" index="/pc/contact-follow-up">人脉跟进记录</el-menu-item>
                <el-menu-item v-if="authList[37]" index="/pc/introduceAnalysis">
                  人脉分析
                </el-menu-item>
                <el-menu-item v-if="authList[49]" index="/pc/introduceRecommendInShop">
                  人脉推荐进店分析
                </el-menu-item>
                <el-menu-item v-if="authList[67]" index="/pc/introduceCommission">
                  人脉推荐需求分佣
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="(authList[15]||authList[56]) && isHaier == 0" index="/pc/service">
              <template slot="title">
                <img src="@/assets/img/el-icon-chat-line-square.svg" class="item-icon" alt="">
                <span slot="title">服务管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[15]" index="/pc/service">
                  服务管理
                </el-menu-item>
                <el-menu-item v-if="authList[56]" index="/pc/serviceTable">
                  已服务报表
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-if="(authList[1] || pcTargQxType != 3) && isHaier == 0" index="/pc/goalControl">
              <img src="@/assets/img/el-icon-goal.svg" class="item-icon" alt="">
              <span slot="title">目标管理</span>
            </el-menu-item>
            <el-menu-item v-if="authList[17]" index="/pc/reportManagement">
              <img src="@/assets/img/el-icon-document-checked.svg" class="item-icon" alt="">
              <span slot="title">工作汇报</span>
            </el-menu-item>
            <el-submenu v-if="authList[66]" index="/pc/clueControl">
              <template slot="title">
                <img src="@/assets/img/el-shop-yc.svg" class="item-icon" alt="">
                <span slot="title">投放管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/pc/placeInfo">
                  投放概况
                </el-menu-item>
                <el-menu-item v-if="isMengTian != 1" index="/pc/dataAnalysis">
                  数据分析
                </el-menu-item>
                <el-menu-item index="/pc/clueControl">
                  线索管理
                </el-menu-item>
                <el-menu-item index="/pc/channelManagement">
                  渠道管理
                </el-menu-item>
                <el-menu-item index="/pc/labelCenter">
                  标签管理
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="((authList[14]||authList[19]||authList[20]||authList[50]||authList[54])&&userInfo.agentid!=-1)||authList[21]||authList[32]||authList[48]||(authList[41]&&userInfo.factoryFlag!=1)||authList[52]||authList[58]||authList[61]" index="/pc/customerBaseSOP">
              <template slot="title">
                <img src="@/assets/img/el-icon-data-analysis.svg" class="item-icon" alt="">
                <span>运营管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[14]&&userInfo.agentid!=-1" index="/pc/customerBaseSOP">
                  SOP管理
                </el-menu-item>
                <el-menu-item v-if="authList[19]&&userInfo.agentid!=-1" index="/pc/massSending">
                  群发管理
                </el-menu-item>
                <el-menu-item v-if="authList[50]&&userInfo.agentid!=-1" index="/pc/friendSending">
                  群发朋友圈
                </el-menu-item>
                <el-menu-item v-if="authList[20]&&userInfo.agentid!=-1" index="/pc/codeList">
                  活码管理
                </el-menu-item>
                <el-menu-item v-if="authList[21]" index="/pc/customerWelcome">
                  欢迎语管理
                </el-menu-item>
                <el-menu-item v-if="authList[32] || authList[48]||authList[58]" index="/pc/taskManagement">
                  任务管理
                </el-menu-item>
                <el-menu-item v-if="authList[61]" index="/pc/taskPackageIndex">
                  任务包
                </el-menu-item>
                <el-menu-item v-if="authList[41]&&userInfo.factoryFlag!=1" index="/pc/operateDelivery">
                  投放管理
                </el-menu-item>
                <el-menu-item v-if="authList[52]" index="/pc/approvalIntoShop">
                  进店接待
                </el-menu-item>
                <el-menu-item v-if="authList[64]" index="/pc/visitRegistration">
                  拜访登记
                </el-menu-item>
                <el-menu-item v-if="authList[54]&&userInfo.agentid!=-1" index="/pc/intelligenceAlert">
                  智能提醒
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="authList[8] || (authList[9] && isHaier == 0)" index="/pc/scriptLibrary">
              <template slot="title">
                <img src="@/assets/img/el-icon-data-line.svg" class="item-icon" alt="">
                <span>应用中心</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[8]" index="/pc/scriptLibrary">
                  话术库
                </el-menu-item>
                <el-menu-item v-if="authList[9] && isHaier == 0" index="/pc/materialLibrary">
                  素材库
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="authList[63]||authList[29]||authList[42]||authList[46]||authList[53]" index="/pc/dataBoard">
              <template slot="title">
                <img src="@/assets/img/el-icon-data-board.svg" class="item-icon" alt="">
                <span>数据报表</span>
              </template>
              <!-- <el-menu-item-group>
                <el-menu-item v-if="authList[63]" index="/pc/digitalWareScreen">
                  数据大屏
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item v-if="authList[29]" index="/pc/dataBoard">
                  数据看板
                </el-menu-item>
              </el-menu-item-group> -->
              <el-menu-item-group>
                <el-menu-item v-if="authList[42]" index="/pc/usageAnalyse">
                  系统使用度
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item v-if="authList[46]" index="/pc/dataIndicator">
                  数据指标统计
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item v-if="authList[53]" index="/pc/cusFollowUpList">
                  客户跟进统计
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item v-if="authList[55]" index="/pc/activityDataCount">
                  活动数据统计
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="(authList[24] || authList[25] || authList[26]) && userInfo.isXc == 1" index="/pc/salaryReportManagement">
              <template slot="title">
                <img src="@/assets/img/el-icon-salary.svg" class="item-icon" alt="">
                <span>智能算薪</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[24]" index="/pc/salaryReportManagement">
                  薪酬报表管理
                </el-menu-item>
                <el-menu-item v-if="authList[25]" index="/pc/salarySchemeManagement">
                  薪酬方案管理
                </el-menu-item>
                <el-menu-item v-if="authList[26]" index="/pc/salaryTeamManagement">
                  薪酬团队管理
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="(authList[22] || authList[23] || authList[30] || authList[35] || authList[38] || authList[39] || authList[45] || authList[47] || authList[51] || authList[57]) && userInfo.factoryFlag == 1" index="/pc/streamORG">
              <template slot="title">
                <img src="@/assets/img/el-icon-data-stream.svg" class="item-icon" alt="">
                <span>上下游</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[22]" index="/pc/streamORG">
                  组织管理
                </el-menu-item>
                <el-menu-item v-if="authList[60]" index="/pc/approvalForm">
                  下游审批单
                </el-menu-item>
                <el-menu-item v-if="authList[23]" index="/pc/streamMaterial">
                  内容管理
                </el-menu-item>
                <el-menu-item v-if="authList[30]" index="/pc/streamCustomerBaseSOP">
                  运营管理
                </el-menu-item>
                <el-menu-item v-if="authList[35]" index="/pc/streamDelivery">
                  投放管理
                </el-menu-item>
                <el-menu-item v-if="authList[38]" index="/pc/dealerAnalysis">
                  人脉分析
                </el-menu-item>
                <el-menu-item v-if="authList[39]||authList[47]||authList[51]" index="/pc/streamTaskMage">
                  任务管理
                </el-menu-item>
                <!-- 默认老版本-->
                <!--                <el-menu-item index="/pc/downStreamDataOrigin" v-if="authList[57]">-->
                <!--                  查看下游数据-->
                <!--                </el-menu-item>-->
                <!-- 数仓版本-->
                <el-menu-item v-if="authList[57]" index="/pc/downStreamData">
                  查看下游数据
                </el-menu-item>
                <el-menu-item v-if="authList[45]" index="/pc/streamUsageAnalyse">
                  系统使用度
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item v-if="authList[10] && isHaierUrl == 1" index="" @click="yxyLink">
              <img src="@/assets/img/el-icon-position.svg" class="item-icon" alt="">
              <span slot="title">营销云</span>
            </el-menu-item> -->
            <el-submenu
              v-if="jxcRoleList[0]||jxcRoleList[1]||jxcRoleList[2]||jxcRoleList[3]||jxcRoleList[4]||jxcRoleList[5]||jxcRoleList[6]||jxcRoleList[7]||jxcRoleList[18]||jxcRoleList[19]
                ||jxcRoleList[23]||jxcRoleList[27]||jxcRoleList[28]||jxcRoleList[29]||jxcRoleList[30]||jxcRoleList[31]||jxcRoleList[32]||jxcRoleList[33]"
              index="/pc/contractList"
            >
              <template slot="title">
                <img src="@/assets/img/el-icon-purchase.svg" class="item-icon" alt="">
                <span>进销存</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="jxcRoleList[0]" index="/pc/contractList">
                  合同单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[1]" index="/pc/procurement">
                  请购单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[2]" index="/pc/purchaseOrders">
                  采购单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[3]" index="/pc/stockIn">
                  入库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[27]" index="/pc/purchaseReturn">
                  采购退货单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[4]" index="/pc/putOut">
                  出库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[5]" index="/pc/reject">
                  销售退货单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[6]" index="/pc/otherStockIn">
                  其他入库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[7]" index="/pc/otherPutOut">
                  其他出库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[18]" index="/pc/transferOrder">
                  调拨单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[19]" index="/pc/inventoryList">
                  盘点单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[28]" index="/pc/inventoryGainDoc">
                  盘盈单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[29]" index="/pc/inventoryLossSheet">
                  盘亏单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[23]" index="/pc/distributionOrders">
                  配货单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[30]" index="/pc/consignmentDeliveryDoc">
                  委托代销出库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[31]" index="/pc/consignmentReturnDoc">
                  委托代销退货单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[32]" index="/pc/consignmentReceiptDoc">
                  受托代销入库单
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[33]" index="/pc/consignedSalesReturnDoc">
                  受托代销退货单
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="jxcRoleList[8]||jxcRoleList[9]||jxcRoleList[10]||jxcRoleList[11]" index="/pc/productBasicMessage">
              <template slot="title">
                <img src="@/assets/img/el-icon-basicData.svg" class="item-icon" alt="">
                <span>基础数据管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="jxcRoleList[8]" index="/pc/productBasicMessage">
                  产品基础信息
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[9]" index="/pc/supplierInformation">
                  供应商信息
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[10]" index="/pc/brandInformation">
                  品牌信息
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[11]" index="/pc/warehouseName">
                  仓库名称
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu
              v-if="jxcRoleList[12] || jxcRoleList[13]|| jxcRoleList[14]|| jxcRoleList[15]|| jxcRoleList[16]|| jxcRoleList[17]
                || jxcRoleList[21]|| jxcRoleList[22]|| jxcRoleList[25]|| jxcRoleList[26]|| jxcRoleList[34]|| jxcRoleList[35]|| jxcRoleList[36]|| jxcRoleList[37]"
              index="/pc/exportTable"
            >
              <template slot="title">
                <img src="@/assets/img/el-icon-document.svg" class="item-icon" alt="">
                <span>报表</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="jxcRoleList[12]" index="/pc/goodsPurchase">
                  已请待采报表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[25]" index="/pc/soldButNotSold">
                  已销未出报表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[26]" index="/pc/purchasedButNotPurchased">
                  已采未入报表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[13]" index="/pc/warehouse">
                  库存表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[14] || jxcRoleList[15] || jxcRoleList[16] || jxcRoleList[17]" index="/pc/customerProfit">
                  客户利润表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[21]" index="/pc/extraordinary">
                  商品收发明细表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[22]" index="/pc/summarySheet">
                  商品收发汇总表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[34]" index="/pc/consignmentTable">
                  委托代销库存表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[35]" index="/pc/consignmentDetails">
                  委托代销收发明细表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[36]" index="/pc/consignedTable">
                  受托代销库存表
                </el-menu-item>
                <el-menu-item v-if="jxcRoleList[37]" index="/pc/consignedDetails">
                  受托代销收发明细表
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="isMap == 1" index="/pc/xiaoquList">
              <template slot="title">
                <img src="@/assets/img/el-icon-map-location.svg" class="item-icon" alt="">
                <span>小区地图</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/pc/xiaoquList">
                  小区列表
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="authList[43] && isWaihu == 1" index="/pc/intelligentVoice">
              <template slot="title">
                <img src="@/assets/img/el-phone-yc.svg" class="item-icon" alt="">
                <span>智能外呼</span>
              </template>
              <!-- <el-menu-item-group>
                <el-menu-item index="/pc/intelligentVoice">
                  智能外呼
                </el-menu-item>
                <el-menu-item index="/pc/rechargeRecord">
                  充值中心
                </el-menu-item>
              </el-menu-item-group> -->
              <el-menu-item-group>
                <el-menu-item index="/pc/intelligentVoiceNew">
                  智能外呼
                </el-menu-item>
                <el-menu-item index="/pc/rechargeRecordNew">
                  充值中心
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="(userInfo.admin&&(userInfo.authcorpid=='ww2c3eb632ce06c1f9'||userInfo.authcorpid=='ww7b4059a824467d75')) ||((userInfo.authcorpid == 'wwbd28a9ece0c45ef4' ) && (userInfo.admin || (!userInfo.admin && authList[59]))) " index="/pc/nuimenStorePage">
              <template slot="title">
                <img src="@/assets/img/el-shop-yc.svg" class="item-icon" alt="">
                <span>数字门店</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/pc/nuimenStorePage">
                  门店列表
                </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="/pc/deviceList">
                  设备列表
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-if="isCw == '1' && authList[31]" index="" @click="openCw">
              <img src="@/assets/img/el-icon-document-checked.svg" class="item-icon" alt="">
              <span slot="title">欢客云会计</span>
            </el-menu-item>
            <el-submenu v-if="authList[40] || authList[2] || authList[3] || authList[27] || authList[7] || authList[4] || authList[36] || authList[60]" index="/pc/teamSetting">
              <template slot="title">
                <img src="@/assets/img/el-icon-setting.svg" class="item-icon" alt="">
                <span>设置</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[40]" index="/pc/storeSetting">
                  部门管理
                </el-menu-item>
                <el-menu-item v-if="authList[2]" index="/pc/teamSetting">
                  员工管理
                </el-menu-item>
                <el-menu-item v-if="authList[3]" index="/pc/roleSetting">
                  角色权限
                </el-menu-item>
                <el-menu-item v-if="authList[27]" index="/pc/postSetting">
                  岗位职级
                </el-menu-item>
                <el-menu-item v-if="isJxc == '1'" index="/pc/jxcRoleSetting">
                  进销存角色权限
                </el-menu-item>
                <el-menu-item v-if="authList[7]" index="/pc/succession">
                  离职继承
                </el-menu-item>
                <el-menu-item v-if="authList[4] || authList[36]" index="/pc/systemSetting">
                  系统设置
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if="authList[44]" index="/pc/riskLog">
              <template slot="title">
                <img src="@/assets/img/el-icon-risk.svg" class="item-icon" alt="">
                <span>风控管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if="authList[44]" index="/pc/riskLog">
                  操作记录
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-footer>
          <div id="faceHead">
            <el-dropdown v-if="userInfo.userName" trigger="click">
              <div :class="['el-dropdown-link', { 'el-dropDown-txt-mini': isCollapse }]" :title="userInfo.userName">
                <template v-if="loginSource == 1">超级管理员</template>
                <template v-else>
                  <span v-if="userInfo && !userInfo.userId" class="el-dropDown-txt">{{ userInfo.userName }}</span>
                  <openData v-if="userInfo && userInfo.userId" class="el-dropDown-txt" type="userName" :openid="userInfo.userId" :user-name="userInfo.userName" />
                  <span>@{{ userInfo.authcorpidName }}</span>
                </template>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="signOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-footer>
      </div>
      <el-main>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="item.path">
            {{ item.name }}
          </el-breadcrumb-item>
          <div v-if="feeFlag" class="feeTip">
            <div class="left">
              温馨提示：{{ feeInfo.probation == 1 ? "试" : "使" }}用期剩余<span>{{ feeInfo.overdueDays }}</span>天
            </div>
            <div class="right">
              <div class="payText" @click="toPay">
                立即续费<i class="el-icon-arrow-right" />
              </div>
              <div class="closeText" @click="feeFlag = false">
                <i class="el-icon-error" />
              </div>
            </div>
          </div>
        </el-breadcrumb>
        <div id="faceContainer">
          <div id="faceBox" class="no-overflow">
            <keep-alive :include="keepAlive">
              <router-view />
            </keep-alive>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import openData from '@/components/wwopendata'
import microappMixins from '@/mixins/microapp'
import { setMicroAppMainUserInfo } from '@/utils/microapp'

export default {
  name: 'Face',
  components: {
    openData
  },
  mixins: [microappMixins],
  data() {
    return {
      isHaier: '',
      loadFlag: false,
      foldFlag: false,
      routeList: [],
      breadList: [],
      userInfo: {},
      authList: [
        false, // 客户列表 0
        false, // 目标管理 1
        false, // 员工管理 2
        false, // 角色权限 3
        false, // 系统设置 4
        false, // 需求列表 5
        false, // 介绍人管理 6
        false, // 离职继承 7
        false, // 应用中心 话术库 8
        false, // 应用中心 素材库 9
        false, // 应用中心 营销云 10
        false, // 客户公海 11
        false, // 客户群 12
        false, // 群SOP 13
        false, // 运营管理 14
        false, // 服务管理 15
        false, // 需求导入 16
        false, // 工作汇报 17
        false, // SOP管理 18
        false, // 群发管理 19
        false, // 活码管理 20
        false, // 欢迎语管理 21
        false, // 上下游组织管理 22
        false, // 上下游内容管理 23
        false, // 薪酬报表管理 24
        false, // 薪酬方案管理 25
        false, // 薪酬团队管理 26
        false, // 岗位职级 27
        false, // 介绍人线索管理 28
        false, // 数据看板 29
        false, // 上下游运营管理 30
        false, // 欢客云会计 31
        false, // 任务管理 32
        false, // 介绍人公司管理 33
        false, // 收款明细 34
        false, // 投放管理(上下游) 35
        false, // 系统设置 36
        false, // 介绍人分析 37
        false, // 介绍人分析(上下游) 38
        false, // 任务管理(上下游) 39
        false, // 部门管理 40
        false, // 投放管理 41
        false, // 系统使用度 42
        false, // 智能外呼 43
        false, // 操作记录 44
        false, // 上下游系统使用度 45
        false, // 数据指标统计 46
        false, // 红包任务(上下游) 47
        false, // 红包任务 48
        false, // 人脉推荐进店分析 49
        false, // 群发朋友圈 50
        false, // 上游朋友圈任务 51
        false, // 进店接待 52
        false, // 客户跟进统计 53
        false, // 智能提醒 54
        false, // 活动数据统计 55
        false, // 已服务报表 56
        false, // 查看下游数据 57
        false, // 指令任务 58
        false, // 数字门店 59
        false, // 下游审批单 60
        false, // 任务包 61
        false, // 签单列表 62
        false, // 数据大屏 63
        false, // 拜访登记 64
        false, // 客户池 65
        false, // 新投放管理 66,
        false, // 人脉推荐需求分佣 67
        false // 人脉跟进记录 68
      ],
      jxcRole: '', // 1:导购,2:部门领导,3:财务,4:采购,5:仓库管理员,6:总管理员admin
      isJxc: '0', // '0':进销存相关功能不可用 '1':进销存相关功能可用
      isCw: '0', // '0':财务相关功能不可用 '1':财务相关功能可用
      jxcRoleList: [
        false, // 合同单 tableid=1
        false, // 请购单 tableid=2
        false, // 采购单 tableid=3
        false, // 入库单 tableid=4
        false, // 出库单 tableid=5
        false, // 退货单 tableid=6
        false, // 其他入库单 tableid=7
        false, // 其他出库单 tableid=8
        false, // 产品基础信息 tableid=9
        false, // 供应商信息 tableid=10
        false, // 品牌信息 tableid=11
        false, // 仓库名称 tableid=12
        false, // 已请待采报表  tableid=13
        false, // 库存表 tableid=14
        false, // 客户利润表(明细) tableid=15
        false, // 客户利润表(客户) tableid=16
        false, // 客户利润表(门店) tableid=17
        false, // 客户利润表(导购) tableid=18
        false, // 调拨单 tableid=19
        false, // 盘点单 tableid=20
        false, // 经营分享(手机端) tableid=21
        false, // 商品收发明细表 tableid=22
        false, // 商品收发汇总表 tableid=23
        false, // 配货单 tableid=24
        false, // 客户联查表  tableid=25
        false, // 已销未出报表 tableid=26
        false, // 已采未入报表  tableid=27
        false, // 采购退货单  tableid=28
        false, // 盘盈单  tableid=29
        false, // 盘亏单  tableid=30
        false, // 委托代销出库单  tableid=31
        false, // 委托代销退货单  tableid=32
        false, // 受托代销入库单  tableid=33
        false, // 受托代销退货单  tableid=34
        false, // 委托代销库存表  tableid=35
        false, // 委托代销手法明细表  tableid=36
        false, // 受托代销库存表  tableid=37
        false // 受托代销手法明细表  tableid=38
      ],
      isCollapse: localStorage.getItem('navMenuCollapsed') === 'true',
      feeInfo: {},
      feeFlag: false,
      token: '',
      isHaierUrl: '',
      link: '',
      isMap: '',
      vertical: '/pc/index',
      isShowNum: false,
      isDbnum: false,
      dbnum: this.GLOBAL.approveNum,
      loginSource: '',
      isWaihu: '',
      pcTargQxType: 3,
      isMengTian: process.env.VUE_APP_MENGTIAN === 1
    }
  },
  computed: {
    keepAlive() {
      // console.log(this.$store.getters.keepAlive,'this.$store.getters.keepAlive')
      return this.$store.getters.keepAlive
    }
  },
  watch: {
    $route: function(newVal, oldVal) {
      if (newVal.path == '/pc/dataBoard') {
        localStorage.setItem('pageSize', 15)
        localStorage.setItem('pageIndex', 1)
      }
      this.vertical = localStorage.getItem('vertical') ? localStorage.getItem('vertical') : newVal.path
      if ((newVal.path == '/pc/inventoryLossSheetAdd' || newVal.path == '/pc/inventoryGainDocAdd') && (oldVal.path == '/pc/inventoryList' || oldVal.path == '/pc/inventoryListAdd')) {
        if (newVal.path == '/pc/inventoryLossSheetAdd') { this.vertical = '/pc/inventoryLossSheet'; localStorage.setItem('vertical', '/pc/inventoryLossSheet') }
        if (newVal.path == '/pc/inventoryGainDocAdd') { this.vertical = '/pc/inventoryGainDoc'; localStorage.setItem('vertical', '/pc/inventoryGainDoc') }
      }
      this.makeBread(newVal.path)
      if (newVal.path == '/pc/pay') {
        this.feeFlag = false
      } else if (newVal.path != '/pc/pay' && this.feeInfo.overdueDays <= 15) {
        const localData = JSON.parse(sessionStorage.getItem('userInfo'))
        if (localData.userInfo.admin && localData.isDealer != 1) {
          this.feeFlag = true
        } else {
          this.feeFlag = false
        }
      }
      if (newVal.path == '/pc/index' && newVal.query.action == 'reload') {
        this.doLogin(this.token)
        this.getJxcRoleCanSee() // 进销存权限jxcRole
      }
    },
    // microapp fixed
    userInfo: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return
        setMicroAppMainUserInfo(val)
        setTimeout(() => {
          this.onMicroAppEmitMenuList()
        }, 800)
      }
    }
  },
  created() {
    const that = this
    if (that.$route.query.token) {
      localStorage.setItem('vertical', '/pc/index')
      // that.doLogin(that.$route.query.token);
      that.token = that.$route.query.token
      that.doLoginInCreate(that, that.$route.query.token)
    } else {
      that.userInfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo
      // that.getAgentConfig();
      that.jxcRole = that.userInfo.jxcRole
      that.isJxc = that.userInfo.isJxc
      that.isCw = that.userInfo.isCw
      that.isHaier = that.userInfo.isHaier ? that.userInfo.isHaier : 0
      that.isWaihu = that.userInfo.isWaihu ? that.userInfo.isWaihu : 0
      that.getAuth()
      that.getJxcRoleCanSee() // 进销存权限jxcRole
      that.token = JSON.parse(sessionStorage.userInfo).token
      that.initOption()
    }
    if (that.$route.path == '/pc/index') {
      if (that.$route.query.source && that.$route.query.source == 1) {
        localStorage.setItem('loginSource', 1)
      } else {
        localStorage.setItem('loginSource', 0)
      }
    }
    if (localStorage.getItem('loginSource') == 1) {
      that.loginSource = 1
    }
    if (that.$route.path == '/pc/dataBoard') {
      localStorage.setItem('pageSize', 15)
      localStorage.setItem('pageIndex', 1)
    }
    that.vertical = localStorage.getItem('vertical') ? localStorage.getItem('vertical') : that.$route.path
    that.makeBread(that.$route.path)
  },
  methods: {
    handleMenuCollapse() {
      this.isCollapse = !this.isCollapse

      localStorage.setItem('navMenuCollapsed', this.isCollapse)
    },
    handelselect(key, keypath) {
      localStorage.setItem('vertical', key)
    },
    openColl() {
      const res = this.$router.resolve({
        path: '/pc/collectionDetails'
      })
      window.open(res.href, '_blank')
    },

    openCw() {
      const res = this.$router.resolve({
        path: '/pc/financeIndex'
      })
      window.open(res.href, '_blank')
      localStorage.setItem(
        'breadList',
        JSON.stringify([
          {
            name: '首页',
            path: '/pc/financeIndex',
            selected: true,
            fullPath: '/pc/financeIndex'
          }
        ])
      )
    },
    yxyLink() {
      if (this.feeInfo.isYxy == 0) {
        this.$router.push({ path: '/pc/pay' })
      } else {
        window.open(this.link, '_blank')
      }
    },
    initOption() {
      const userInfo = sessionStorage.getItem('userInfo')
      const token = userInfo == null ? '' : JSON.parse(userInfo).token
      const isHaier = userInfo == null ? '' : JSON.parse(userInfo).userInfo.isHaier ? JSON.parse(userInfo).userInfo.isHaier : 0
      this.isMap = userInfo == null ? '' : JSON.parse(userInfo).userInfo.isMap ? JSON.parse(userInfo).userInfo.isMap : 0
      const hrefUrl = process.env.VUE_APP_YXY_URL
      let url = ''
      let text = ''
      const database = userInfo == null ? '' : JSON.parse(userInfo).userInfo.database ? JSON.parse(userInfo).userInfo.database : 0
      if (database == 4) {
        text = 'token4'
      } else {
        text = 'token'
      }
      if (process.env.NODE_ENV == 'development') {
        // 营销云测试地址
        url = hrefUrl + '?' + text + '=' + token + '&isHaier=' + isHaier
        this.isHaierUrl = isHaier
      } else {
        // 营销云正式版本
        if (isHaier == 1) {
          // 海尔版本
          url = 'http://admin.api.huankehudong.com/admin/auth/sales_login?token=' + token + '&isHaier=' + isHaier
        } else {
          url = hrefUrl + '?' + text + '=' + token
        }
        this.isHaierUrl = 1
      }
      this.link = url
    },
    signOut() {
      sessionStorage.removeItem('userInfo')
      this.userInfo = {}
      this.isMap = 0
      this.isDbnum = false
      for (let i = 0; i < this.authList.length; i++) {
        this.authList[i] = false
      }
      for (let i = 0; i < this.jxcRoleList.length; i++) {
        this.jxcRoleList[i] = false
      }
      if (this.$route.path != '/pc/index') {
        this.$router.replace({
          path: '/pc/index'
        })
      }
    },
    // 右边内容导航
    makeBread(path) {
      const temp = []
      if (path == '/pc/index') {
        temp.push({
          name: '首页',
          path: ''
        })
      } else if (path == '/pc/customerList') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '客户列表',
          path: ''
        })
      } else if (path == '/pc/collectionDetails') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '收款明细',
          path: ''
        })
      } else if (path == '/pc/demandList') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '需求列表',
          path: ''
        })
      } else if (path == '/pc/signList') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '签单列表',
          path: ''
        })
      } else if (path == '/pc/customerDetail') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '客户列表',
          path: '/pc/customerList'
        })
        temp.push({
          name: '客户详情',
          path: ''
        })
      } else if (path == '/pc/introduceControl') {
        temp.push({
          name: '人脉管理',
          path: ''
        })
        temp.push({
          name: '人脉管理',
          path: ''
        })
      } else if (path == '/pc/introduceClue') {
        temp.push({
          name: '人脉管理',
          path: ''
        })
        temp.push({
          name: '人脉线索管理',
          path: ''
        })
      } else if (path == '/pc/introduceCompany') {
        temp.push({
          name: '人脉管理',
          path: ''
        })
        temp.push({
          name: '人脉公司管理',
          path: ''
        })
      } else if (path === '/pc/contact-follow-up') {
        temp.push({ name: '人脉管理', path: '' }, { name: '人脉跟进记录', path: '' })
      } else if (path == '/pc/introduceRecommendInShop') {
        temp.push({
          name: '人脉管理',
          path: ''
        })
        temp.push({
          name: '人脉推荐进店',
          path: ''
        })
      } else if (path == '/pc/service') {
        temp.push({
          name: '服务管理',
          path: ''
        })
        temp.push({
          name: '服务管理',
          path: ''
        })
      } else if (path == '/pc/goalControl') {
        temp.push({
          name: '目标管理',
          path: '/pc/goalControl'
        })
      } else if (path == '/pc/reportManagement') {
        temp.push({
          name: '工作汇报',
          path: '/pc/reportManagement'
        })
      } else if (path == '/pc/reportDetail') {
        temp.push({
          name: '工作汇报',
          path: '/pc/reportManagement'
        })
        temp.push({
          name: '汇报详情',
          path: '/pc/reportDetail'
        })
      } else if (path == '/pc/scriptLibrary') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '话术库',
          path: ''
        })
      } else if (path == '/pc/materialLibrary') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '素材库',
          path: ''
        })
      } else if (path == '/pc/materialDetail') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '素材库',
          path: '/pc/materialLibrary'
        })
      } else if (path == '/pc/materialFormDetail') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '素材库',
          path: '/pc/materialLibrary?tab=1'
        })
        temp.push({
          name: '表单',
          path: ''
        })
      } else if (path == '/pc/materialFormDataList') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '素材库',
          path: '/pc/materialLibrary?tab=1'
        })
        temp.push({
          name: '统计数据',
          path: '/pc/materialFormDataList'
        })
      } else if (path == '/pc/example2') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '素材库',
          path: '/pc/materialLibrary'
        })
      } else if (path == '/pc/teamSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '员工管理',
          path: ''
        })
      } else if (path == '/pc/roleSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '角色权限',
          path: ''
        })
      } else if (path == '/pc/jxcRoleSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '进销存角色权限',
          path: ''
        })
      } else if (path == '/pc/systemSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '系统设置',
          path: ''
        })
      } else if (path == '/pc/pay') {
        temp.push({
          name: '续费充值',
          path: ''
        })
      } else if (path == '/pc/agreement') {
        temp.push({
          name: '用户协议',
          path: ''
        })
      } else if (path == '/pc/succession') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '离职继承',
          path: ''
        })
      } else if (path == '/pc/successionDetail') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '离职继承',
          path: '/pc/succession'
        })
        temp.push({
          name: '离职继承详情',
          path: ''
        })
      } else if (path == '/pc/contractList') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '合同单',
          path: ''
        })
      } else if (path == '/pc/stockIn') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '入库单',
          path: ''
        })
      } else if (path == '/pc/putOut') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '出库单',
          path: ''
        })
      } else if (path == '/pc/reject') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '销售退货单',
          path: ''
        })
      } else if (path == '/pc/otherStockIn') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '其他入库单',
          path: ''
        })
      } else if (path == '/pc/otherPutOut') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '其他出库单',
          path: ''
        })
      } else if (path == '/pc/transferOrder') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '调拨单',
          path: ''
        })
      } else if (path == '/pc/inventoryList') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '盘点单',
          path: ''
        })
      } else if (path == '/pc/procurement') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '请购单',
          path: ''
        })
      } else if (path == '/pc/purchaseOrders') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '采购单',
          path: ''
        })
      } else if (path == '/pc/distributionOrders') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '配货单',
          path: ''
        })
      } else if (path == '/pc/purchaseReturn') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '采购退货单',
          path: ''
        })
      } else if (path == '/pc/inventoryGainDoc') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '盘盈单',
          path: ''
        })
      } else if (path == '/pc/inventoryLossSheet') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '盘亏单',
          path: ''
        })
      } else if (path == '/pc/consignmentDeliveryDoc') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '委托代销出库单',
          path: ''
        })
      } else if (path == '/pc/consignmentReturnDoc') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '委托代销退货单',
          path: ''
        })
      } else if (path == '/pc/consignmentReceiptDoc') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '受托代销入库单',
          path: ''
        })
      } else if (path == '/pc/consignedSalesReturnDoc') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '受托代销退货单',
          path: ''
        })
      } else if (path == '/pc/consignmentTable') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '委托代销库存表',
          path: ''
        })
      } else if (path == '/pc/consignmentDetails') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '委托代销收发明细表',
          path: ''
        })
      } else if (path == '/pc/consignedTable') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '受托代销库存表',
          path: ''
        })
      } else if (path == '/pc/consignedDetails') {
        temp.push({
          name: '进销存',
          path: ''
        })
        temp.push({
          name: '受托代销收发明细表',
          path: ''
        })
      } else if (path == '/pc/auditList') {
        temp.push({
          name: '审核代办',
          path: ''
        })
      } else if (path == '/pc/productBasicMessage') {
        temp.push({
          name: '基础数据管理',
          path: ''
        })
        temp.push({
          name: '产品基础信息',
          path: ''
        })
      } else if (path == '/pc/supplierInformation') {
        temp.push({
          name: '基础数据管理',
          path: ''
        })
        temp.push({
          name: '供应商信息',
          path: ''
        })
      } else if (path == '/pc/brandInformation') {
        temp.push({
          name: '基础数据管理',
          path: ''
        })
        temp.push({
          name: '品牌信息',
          path: ''
        })
      } else if (path == '/pc/warehouseName') {
        temp.push({
          name: '基础数据管理',
          path: ''
        })
        temp.push({
          name: '仓库名称',
          path: ''
        })
      } else if (path == '/pc/goodsPurchase') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '已请待采报表',
          path: ''
        })
      } else if (path == '/pc/soldButNotSold') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '已销未出报表',
          path: ''
        })
      } else if (path == '/pc/purchasedButNotPurchased') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '已采未入报表',
          path: ''
        })
      } else if (path == '/pc/warehouse') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '库存表',
          path: ''
        })
      } else if (path == '/pc/customerProfit') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '客户利润表',
          path: ''
        })
      } else if (path == '/pc/extraordinary') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '商品收发明细表',
          path: ''
        })
      } else if (path == '/pc/summarySheet') {
        temp.push({
          name: '报表',
          path: ''
        })
        temp.push({
          name: '商品收发汇总表',
          path: ''
        })
      } else if (path == '/pc/nuimenStorePage') {
        temp.push({
          name: '数字门店',
          path: ''
        })
        temp.push({
          name: '门店列表',
          path: ''
        })
      } else if (path == '/pc/deviceList') {
        temp.push({
          name: '数字门店',
          path: ''
        })
        temp.push({
          name: '设备列表',
          path: ''
        })
      } else if (path == '/pc/intelligentVoice') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: ''
        })
      } else if (path == '/pc/rechargeRecord') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '充值中心',
          path: ''
        })
      } else if (path == '/pc/payPage') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '充值中心',
          path: '/pc/rechargeRecord'
        })
        temp.push({
          name: '充值',
          path: ''
        })
      } else if (path == '/pc/intelligentVoiceDetail') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: '/pc/intelligentVoice'
        })
        temp.push({
          name: '外呼详情',
          path: ''
        })
      } else if (path == '/pc/billsDetail') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: '/pc/intelligentVoice'
        })
        temp.push({
          name: '账单详情',
          path: ''
        })
      } else if (path == '/pc/intelligentVoiceNew') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: ''
        })
      } else if (path == '/pc/rechargeRecordNew') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '充值中心',
          path: ''
        })
      } else if (path == '/pc/intelligentVoiceNewDetail') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: '/pc/intelligentVoiceNew'
        })
        temp.push({
          name: '外呼详情',
          path: ''
        })
      } else if (path == '/pc/billsDetailNew') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '外呼列表',
          path: '/pc/intelligentVoiceNew'
        })
        temp.push({
          name: '账单详情',
          path: ''
        })
      } else if (path == '/pc/payPageNew') {
        temp.push({
          name: '智能外呼',
          path: ''
        })
        temp.push({
          name: '充值中心',
          path: '/pc/rechargeRecordNew'
        })
        temp.push({
          name: '充值',
          path: ''
        })
      } else if (path == '/pc/xiaoquList') {
        temp.push({
          name: '小区地图',
          path: ''
        })
        temp.push({
          name: '小区列表',
          path: ''
        })
      } else if (path == '/pc/audit') {
        temp.push({
          name: '小区地图',
          path: ''
        })
        temp.push({
          name: '小区楼栋审核记录',
          path: ''
        })
      } else if (path == '/pc/xiaoquAudit') {
        temp.push({
          name: '小区地图',
          path: ''
        })
        temp.push({
          name: '小区审核记录',
          path: ''
        })
      } else if (path == '/pc/buildingDetail') {
        temp.push({
          name: '小区地图',
          path: ''
        })
        temp.push({
          name: '小区列表',
          path: '/pc/xiaoquList'
        })
        temp.push({
          name: '小区详情',
          path: ''
        })
      } else if (path == '/pc/operationLog') {
        temp.push({
          name: '小区地图',
          path: ''
        })
        temp.push({
          name: '小区列表',
          path: '/pc/xiaoquList'
        })
        temp.push({
          name: '操作日志',
          path: ''
        })
      } else if (path == '/pc/seaList') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '客户公海',
          path: ''
        })
      } else if (path == '/pc/customerAssociated') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '客户联查表',
          path: ''
        })
      } else if (path == '/pc/customerBase') {
        temp.push({
          name: '客户管理',
          path: ''
        })
        temp.push({
          name: '客户群',
          path: '/pc/customerBase'
        })
      } else if (path == '/pc/customerBaseSOP') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: 'SOP管理',
          path: ''
        })
      } else if (path == '/pc/massSending') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '群发管理',
          path: '/pc/massSending'
        })
      } else if (path == '/pc/customerGroupBaseSOPData') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '群SOP',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '数据',
          path: ''
        })
      } else if (path == '/pc/customerBaseSOPData') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '客户SOP',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '数据',
          path: ''
        })
      } else if (path == '/pc/customerBaseSOPDetail') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '客户SOP',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '新增客户SOP',
          path: ''
        })
      } else if (path == '/pc/customerGroupBaseSOPDetail') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '群SOP',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '新建群SOP',
          path: ''
        })
      } else if (path == '/pc/taskManagement') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '任务管理',
          path: '/pc/taskManagement'
        })
      } else if (path == '/pc/visitRegistration') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '拜访登记',
          path: ''
        })
      } else if (path == '/pc/operateDelivery') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '投放管理',
          path: '/pc/operateDelivery'
        })
      } else if (path == '/pc/taskManageDetail') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '任务管理',
          path: '/pc/taskManagement'
        })
        temp.push({
          name: '部门报表',
          path: '/pc/taskManageDetail'
        })
      } else if (path == '/pc/installCode') {
        // temp.push({
        //   name: '营销云',
        //   path: ''
        // })
      } else if (path == '/pc/codeList') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '活码管理',
          path: ''
        })
      } else if (path == '/pc/codeDetail') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '活码管理',
          path: '/pc/codeList'
        })
        temp.push({
          name: '活码详情',
          path: ''
        })
      }
      if (path == '/pc/customerWelcome') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '欢迎语管理',
          path: '/pc/customerWelcome'
        })
      }
      if (path == '/pc/customerWelcomeAdd') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '欢迎语管理',
          path: '/pc/customerWelcome'
        })
        temp.push({
          name: '欢迎语详情',
          path: ''
        })
      } else if (path == '/pc/codeChart') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '活码管理',
          path: '/pc/codeList'
        })
        temp.push({
          name: '数据统计',
          path: ''
        })
      } else if (path == '/pc/codeForm') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '活码管理',
          path: '/pc/codeList'
        })
        temp.push({
          name: this.$route.query.id ? '修改活码' : '新建活码',
          path: ''
        })
      } else if (path == '/pc/streamORG') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '组织管理',
          path: ''
        })
      } else if (path == '/pc/approvalForm') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '下游审批单',
          path: ''
        })
      } else if (path == '/pc/streamMaterial') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '内容管理',
          path: ''
        })
      } else if (path == '/pc/streamMaterialDetail') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '内容管理',
          path: ''
        })
      } else if (path == '/pc/streamMaterialTags') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '内容管理',
          path: '/pc/streamMaterialDetail'
        })
        temp.push({
          name: '标签管理',
          path: ''
        })
      } else if (path == '/pc/streamMaterialFormDataList') {
        temp.push(
          { name: '上下游', path: '' },
          { name: '内容管理', path: '/pc/streamMaterial' },
          { name: '统计数据', path: '' }
        )
      } else if (path == '/pc/streamCustomerBaseSOP') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '运营管理',
          path: '/pc/streamCustomerBaseSOP'
        })
      } else if (path == '/pc/streamCustomerBaseSOPDetail') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '运营管理',
          path: '/pc/streamCustomerBaseSOP'
        })
        temp.push({
          name: '客户SOP',
          path: '/pc/streamCustomerBaseSOPDetail'
        })
      } else if (path == '/pc/streamTaskMage') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '任务管理',
          path: '/pc/streamTaskMage'
        })
      } else if (path == '/pc/streamTaskMageDetail') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '任务管理',
          path: '/pc/streamTaskMage'
        })
        temp.push({
          name: '部门报表',
          path: '/pc/streamTaskMageDetail'
        })
      } else if (path == '/pc/salaryReportManagement') {
        temp.push({
          name: '智能算薪',
          path: ''
        })
        temp.push({
          name: '薪酬报表管理',
          path: ''
        })
      } else if (path == '/pc/salarySchemeManagement') {
        temp.push({
          name: '智能算薪',
          path: ''
        })
        temp.push({
          name: '薪酬方案管理',
          path: ''
        })
      } else if (path == '/pc/salarySetPenal') {
        temp.push({
          name: '智能算薪',
          path: ''
        })
        temp.push({
          name: '薪酬方案管理',
          path: 'pc/salarySchemeManagement'
        })
        temp.push({
          name: '设置奖罚',
          path: ''
        })
      } else if (path == '/pc/salaryTeamManagement') {
        temp.push({
          name: '智能算薪',
          path: ''
        })
        temp.push({
          name: '薪酬团队管理',
          path: ''
        })
      } else if (path == '/pc/salaryTeamManagement') {
        temp.push({
          name: '智能算薪',
          path: ''
        })
        temp.push({
          name: '薪酬团队管理',
          path: 'pc/salaryTeamManagement'
        })
        temp.push({
          name: '设置奖罚',
          path: ''
        })
      } else if (path == '/pc/postSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '岗位职级',
          path: ''
        })
      } else if (path == '/pc/streamFormDataList') {
        temp.push({
          name: '应用中心',
          path: ''
        })
        temp.push({
          name: '上下游素材',
          path: '/pc/materialLibrary?tab=7&tabCell=7-1'
        })
        temp.push({
          name: '表单',
          path: '/pc/materialLibrary?tab=7&tabCell=7-2'
        })
        temp.push({
          name: '统计数据',
          path: '/pc/streamFormDataList'
        })
      } else if (path == '/pc/digitalWareScreen') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '数据大屏',
          path: ''
        })
      } else if (path == '/pc/dataBoard') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '数据看板',
          path: ''
        })
      } else if (path == '/pc/dataScreenFactory') {
        temp.push({
          name: '数据看板',
          path: ''
        })
      } else if (path == '/pc/streamDelivery') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '投放管理',
          path: ''
        })
      } else if (path == '/pc/channelManage') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '投放管理',
          path: '/pc/streamDelivery'
        })
        temp.push({
          name: '渠道配置',
          path: ''
        })
      } else if (path == '/pc/channelConfiguration') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '投放管理',
          path: '/pc/streamDelivery'
        })
        temp.push({
          name: '渠道配置列表',
          path: '/pc/channelManage'
        })
        temp.push({
          name: '渠道配置详情',
          path: ''
        })
      } else if (path == '/pc/dealerAnalysis') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '人脉分析',
          path: '/pc/dealerAnalysis'
        })
      } else if (path == '/pc/introduceAnalysis') {
        temp.push({
          name: '人脉管理',
          path: ''
        })
        temp.push({
          name: '人脉分析',
          path: '/pc/introduceAnalysis'
        })
      } else if (path == '/pc/operationChannelManage') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '投放管理',
          path: '/pc/operateDelivery'
        })
        temp.push({
          name: '渠道配置',
          path: ''
        })
      } else if (path == '/pc/operationChannelConfiguration') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '投放管理',
          path: '/pc/operateDelivery'
        })
        temp.push({
          name: '渠道配置',
          path: '/pc/operationChannelManage'
        })
        temp.push({
          name: '渠道配置详情',
          path: '/pc/operationChannelConfiguration'
        })
      } else if (path == '/pc/storeSetting') {
        temp.push({
          name: '设置',
          path: ''
        })
        temp.push({
          name: '部门管理',
          path: ''
        })
      } else if (path == '/pc/usageAnalyse') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '系统使用度',
          path: ''
        })
      } else if (path == '/pc/riskLog') {
        temp.push({
          name: '风控管理',
          path: ''
        })
        temp.push({
          name: '操作记录',
          path: ''
        })
      } else if (path == '/pc/baseCustomerDetail') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '投放管理',
          path: '/pc/operateDelivery'
        })
        temp.push({
          name: '客户分配',
          path: '/pc/operateDelivery?tab=1'
        })
        temp.push({
          name: '客户详情',
          path: '/pc/baseCustomerDetail'
        })
      } else if (path == '/pc/streamCustomerDetail') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '投放管理',
          path: '/pc/streamDelivery'
        })
        temp.push({
          name: '客户分配',
          path: '/pc/streamDelivery?tab=3'
        })
        temp.push({
          name: '客户详情',
          path: '/pc/streamCustomerDetail'
        })
      } else if (path == '/pc/reportData') {
        temp.push({
          name: '工作汇报',
          path: '/pc/reportManagement'
        })
        temp.push({
          name: '数据',
          path: '/pc/reportData'
        })
      } else if (path == '/pc/streamUsageAnalyse') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '系统使用度',
          path: ''
        })
      } else if (path == '/pc/dataIndicator') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '数据指标统计',
          path: ''
        })
      } else if (path == '/pc/pyqSendingDetail') {
        temp.push({
          name: '朋友圈任务详情',
          path: ''
        })
      } else if (path == '/pc/pyqSendingForm') {
        temp.push({
          name: '朋友圈任务',
          path: ''
        })
      } else if (path == '/pc/redPacketSet') {
        temp.push({
          name: '红包任务',
          path: ''
        })
      } else if (path == '/pc/streamTaskData') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '任务管理',
          path: '/pc/streamTaskMage'
        })
        temp.push({
          name: '自建任务管理',
          path: '/pc/streamTaskMage?tab=2'
        })
        temp.push({
          name: '批量分发下游任务数据',
          path: '/pc/streamTaskData'
        })
      } else if (path == '/pc/friendSending') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '群发朋友圈',
          path: ''
        })
      } else if (path == '/pc/redPacketExamine') {
        temp.push({
          name: '红包任务',
          path: ''
        })
      } else if (path == '/pc/approvalIntoShop') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '进店接待',
          path: ''
        })
      } else if (path == '/pc/intelligenceAlert') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '智能提醒',
          path: ''
        })
      } else if (path == '/pc/cusFollowUpList') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '客户跟进统计',
          path: ''
        })
      } else if (path == '/pc/activityDataCount') {
        temp.push({
          name: '数据报表',
          path: ''
        })
        temp.push({
          name: '活动数据统计',
          path: ''
        })
      } else if (path == '/pc/redPacketData') {
        temp.push({
          name: '红包任务数据统计',
          path: ''
        })
      } else if (path == '/pc/serviceTable') {
        temp.push({
          name: '服务管理',
          path: ''
        })
        temp.push({
          name: '已服务报表',
          path: ''
        })
      } else if (path == '/pc/downStreamDataOrigin') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '查看下游数据',
          path: ''
        })
      } else if (path == '/pc/downStreamData') {
        temp.push({
          name: '上下游',
          path: ''
        })
        temp.push({
          name: '查看下游数据',
          path: ''
        })
      } else if (path == '/pc/commandTaskMage') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '任务管理',
          path: '/pc/taskManagement'
        })
        temp.push({
          name: '指令任务管理',
          path: '/pc/commandTaskMage'
        })
      } else if (path == '/pc/taskFormDetail') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '任务管理',
          path: '/pc/taskManagement'
        })
        temp.push({
          name: '指令任务管理',
          path: '/pc/commandTaskMage'
        })
        temp.push({
          name: '指令任务详情',
          path: '/pc/taskFormDetail'
        })
      } else if (path == '/pc/taskFinishData') {
        temp.push({
          name: '运营管理',
          path: '/pc/customerBaseSOP'
        })
        temp.push({
          name: '任务管理',
          path: '/pc/taskManagement'
        })
        temp.push({
          name: '指令任务管理',
          path: '/pc/taskManagement?tab=4'
        })
        temp.push({
          name: '指令任务完成情况',
          path: '/pc/taskFinishData'
        })
      } else if (path == '/pc/taskPackageIndex') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '任务包',
          path: ''
        })
      } else if (path == '/pc/taskPackageSet') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '任务包',
          path: ''
        })
      } else if (path == '/pc/taskPackageData') {
        temp.push({
          name: '运营管理',
          path: ''
        })
        temp.push({
          name: '任务包数据',
          path: ''
        })
      } else if (path == '/pc/clueControl') {
        temp.push({
          name: '投放管理',
          path: ''
        })
        temp.push({
          name: '线索管理',
          path: ''
        })
      } else if (path == '/pc/pushClueSet') {
        temp.push({
          name: '线索管理',
          path: ''
        })
        temp.push({
          name: '入库规则配置',
          path: ''
        })
      } else if (path == '/pc/distributeClueSet') {
        temp.push({
          name: '线索管理',
          path: ''
        })
        temp.push({
          name: '分发规则配置',
          path: ''
        })
      } else if (path == '/pc/channelManagement') {
        temp.push({
          name: '投放管理',
          path: ''
        })
        temp.push({
          name: '渠道管理',
          path: ''
        })
      }

      this.breadList = temp
    },
    toPay() {
      this.$router.push({ path: '/pc/pay' })
    },
    getFee() {
      const that = this
      that.loadFlag = true
      that.$request.doPost('/quotation/getQuotationList', {}, (res) => {
        if (res.data.code != 0) {
          that.$alert(res.data.message, '提示')
          return
        }
        that.feeInfo = res.data.result
        if (that.$route.path == '/pc/pay') {
          that.feeFlag = false
        } else if (that.$route.path != '/pc/pay' && that.feeInfo.overdueDays <= 15) {
          const localData = JSON.parse(sessionStorage.getItem('userInfo'))
          if (localData.userInfo.admin && localData.isDealer != 1) {
            that.feeFlag = true
          } else {
            that.feeFlag = false
          }
        }
      }, (err) => {
        that.$alert('当前网络异常，请稍后重试', '提示')
      }, (end) => {
        that.loadFlag = false
      })
    },
    getAuth() {
      const that = this
      if (sessionStorage.getItem('userInfo') != null && !JSON.parse(sessionStorage.getItem('userInfo')).userInfo.admin) {
        that.loadFlag = true
        that.$request.doPost('/role-permissions-distribution/getPermissionsType', {}, (res) => {
          if (res.data.code != 0) {
            that.$alert(res.data.message, '提示')
            return
          }
          const result = res.data.result ? res.data.result : {}
          sessionStorage.setItem('rootData', JSON.stringify(result))
          that.pcTargQxType = result.pcTargQxType ? result.pcTargQxType : 3
        }, (err) => {
          that.$alert('当前网络异常，请稍后重试', '提示')
        }, (end) => {
          that.loadFlag = false
        })
        that.$request.doPost('/role-permissions/getUserPermissions', {}, (res) => {
          if (res.data.code != 0) {
            that.$alert(res.data.message, '提示')
            return
          }
          const result = res.data.result == null ? [] : res.data.result.split(',')
          const localData = JSON.parse(sessionStorage.getItem('userInfo'))
          localData.auth = result
          sessionStorage.setItem('userInfo', JSON.stringify(localData))
          for (let i = 0; i < result.length; i++) {
            if (result[i] == '5.1.1') {
              that.authList[0] = true
            } else if (result[i] == '9.1.1') {
              that.authList[1] = true
            } else if (result[i] == '6.1.2') {
              that.authList[2] = true
            } else if (result[i] == '6.1.3') {
              that.authList[3] = true
            } else if (result[i] == '6.1.1') {
              that.authList[4] = true
            } else if (result[i] == '5.1.2') {
              that.authList[5] = true
            } else if (result[i] == '12.1.1') {
              that.authList[6] = true
            } else if (result[i] == '6.1.4') {
              that.authList[7] = true
            } else if (result[i] == '14.1.1') {
              that.authList[8] = true
            } else if (result[i] == '14.1.2') {
              that.authList[9] = true
            } else if (result[i] == '14.1.3') {
              that.authList[10] = true
            } else if (result[i] == '5.1.3') {
              that.authList[11] = true
            } else if (result[i] == '5.1.4') {
              that.authList[12] = true
            } else if (result[i] == '5.2.7') {
              that.authList[13] = true
            } else if (result[i] == '18.1.1') {
              that.authList[14] = true
            } else if (result[i] == '19.1.1') {
              that.authList[15] = true
            } else if (result[i] == '5.2.8') {
              that.authList[16] = true
            } else if (result[i] == '21.1.1') {
              that.authList[17] = true
            } else if (result[i] == '18.1.1') {
              that.authList[18] = true
            } else if (result[i] == '18.1.2') {
              that.authList[19] = true
            } else if (result[i] == '18.1.3') {
              that.authList[20] = true
            } else if (result[i] == '18.1.4') {
              that.authList[21] = true
            } else if (result[i] == '22.1.1') {
              that.authList[22] = true
            } else if (result[i] == '22.1.2') {
              that.authList[23] = true
            } else if (result[i] == '23.1.1') {
              that.authList[24] = true
            } else if (result[i] == '23.1.2') {
              that.authList[25] = true
            } else if (result[i] == '23.1.3') {
              that.authList[26] = true
            } else if (result[i] == '6.1.5') {
              that.authList[27] = true
            } else if (result[i] == '12.1.2') {
              that.authList[28] = true
            } else if (result[i] == '25.1.1') {
              that.authList[29] = true
            } else if (result[i] == '22.1.3') {
              that.authList[30] = true
            } else if (result[i] == '26.1.1') {
              that.authList[31] = true
            } else if (result[i] == '18.1.5') {
              that.authList[32] = true
            } else if (result[i] == '12.1.3') {
              that.authList[33] = true
            } else if (result[i] == '5.1.5') {
              that.authList[34] = true
            } else if (result[i] == '22.1.4') {
              that.authList[35] = true
            } else if (result[i] == '6.3') {
              that.authList[36] = true
            } else if (result[i] == '12.1.4') {
              that.authList[37] = true
            } else if (result[i] == '22.1.5') {
              that.authList[38] = true
            } else if (result[i] == '22.1.6') {
              that.authList[39] = true
            } else if (result[i] == '6.1.6') {
              that.authList[40] = true
            } else if (result[i] == '18.1.6') {
              that.authList[41] = true
            } else if (result[i] == '25.1.2') {
              that.authList[42] = true
            } else if (result[i] == '27.1.1') {
              that.authList[43] = true
            } else if (result[i] == '29.1.1') {
              that.authList[44] = true
            } else if (result[i] == '22.1.7') {
              that.authList[45] = true
            } else if (result[i] == '25.1.3') {
              that.authList[46] = true
            } else if (result[i] == '22.1.8') {
              that.authList[47] = true
            } else if (result[i] == '18.1.7') {
              that.authList[48] = true
            } else if (result[i] == '12.1.5') {
              that.authList[49] = true
            } else if (result[i] == '12.1.6') {
              that.authList[67] = true
            } else if (result[i] == '12.1.7') {
              that.authList[68] = true
            } else if (result[i] == '18.1.8') {
              that.authList[50] = true
            } else if (result[i] == '22.1.9') {
              that.authList[51] = true
            } else if (result[i] == '18.1.9') {
              that.authList[52] = true
            } else if (result[i] == '25.1.4') {
              that.authList[53] = true
            } else if (result[i] == '18.1.10') {
              that.authList[54] = true
            } else if (result[i] == '25.1.5') {
              that.authList[55] = true
            } else if (result[i] == '19.1.2') {
              that.authList[56] = true
            } else if (result[i] == '22.1.10') {
              that.authList[57] = true
            } else if (result[i] == '18.1.11') {
              that.authList[58] = true
            } else if (result[i] == '33.1.1') {
              that.authList[59] = true
            } else if (result[i] == '22.1.11') {
              that.authList[60] = true
            } else if (result[i] == '18.1.17') {
              that.authList[61] = true
            } else if (result[i] == '5.1.6') {
              that.authList[62] = true
            } else if (result[i] == '25.1.9') {
              that.authList[63] = true
            } else if (result[i] == '18.1.18') {
              that.authList[64] = true
            } else if (result[i] == '36.1.1') {
              that.authList[66] = true
            }
            that.$forceUpdate()
          }
        }, (err) => {
          that.$alert('当前网络异常，请稍后重试', '提示')
        }, (end) => {
          that.loadFlag = false
        })
      } else if (sessionStorage.getItem('userInfo') != null && JSON.parse(sessionStorage.getItem('userInfo')).userInfo.admin) {
        for (let i = 0; i < that.authList.length; i++) {
          that.authList[i] = true
        }
        sessionStorage.removeItem('rootData')
        that.pcTargQxType = 1
      }
      that.getFee()
    },
    doLogin(token) {
      const that = this
      const obj = {
        token: token,
        userInfo: {}
      }
      that.loadFlag = true
      sessionStorage.setItem('userInfo', JSON.stringify(obj))
      that.$request.doPost('/user/pc/login', {}, (res) => {
        if (res.data.code != 0) {
          that.$alert(res.data.message, '提示')
          return
        }
        if (!res.data.result.userInfo.isHaier) {
          res.data.result.userInfo.isHaier = 0
        }
        if (!res.data.result.userInfo.factoryFlag) {
          res.data.result.userInfo.factoryFlag = 0
        }
        if (!res.data.result.userInfo.isXc) {
          res.data.result.userInfo.isXc = 0
        }
        if (!res.data.result.userInfo.initDeptFlag) {
          res.data.result.userInfo.initDeptFlag = 0
        }
        if (!res.data.result.userInfo.isWaihu) {
          res.data.result.userInfo.isWaihu = 0
        }
        obj.isDealer = res.data.result.isDealer
        obj.pid = res.data.result.pid
        obj.userInfo = res.data.result.userInfo
        sessionStorage.setItem('userInfo', JSON.stringify(obj))
        that.userInfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo
        that.isJxc = that.userInfo.isJxc
        that.isCw = that.userInfo.isCw
        that.isHaier = that.userInfo.isHaier
        that.isWaihu = that.userInfo.isWaihu
        // that.getAgentConfig();
        that.getJxcRoleCanSee() // 进销存权限jxcRole
        that.getAuth()
        that.initOption()
        window.history.replaceState('', '', window.location.href.split('?')[0])
      }, (err) => {
        that.$alert('当前网络异常，请稍后重试', '提示')
      }, (end) => {
        that.loadFlag = false
      })
    },
    getJxcRoleCanSee() {
      const that = this
      that.loadFlag = true
      that.$request.doPost('/tjxcroleinfo/getUserJxcRoleCansee', {}, (res) => {
        if (res.data.code != 0) {
          that.$alert(res.data.message, '提示')
          return
        }
        const result = res.data.result == null ? [] : res.data.result
        for (let i = 0; i < result.length; i++) {
          if (result[i].tableId == 1 && result[i].canSee == '1') {
            that.jxcRoleList[0] = true
          }
          if (result[i].tableId == 2 && result[i].canSee == '1') {
            that.jxcRoleList[1] = true
          }
          if (result[i].tableId == 3 && result[i].canSee == '1') {
            that.jxcRoleList[2] = true
          }
          if (result[i].tableId == 4 && result[i].canSee == '1') {
            that.jxcRoleList[3] = true
          }
          if (result[i].tableId == 5 && result[i].canSee == '1') {
            that.jxcRoleList[4] = true
          }
          if (result[i].tableId == 6 && result[i].canSee == '1') {
            that.jxcRoleList[5] = true
          }
          if (result[i].tableId == 7 && result[i].canSee == '1') {
            that.jxcRoleList[6] = true
          }
          if (result[i].tableId == 8 && result[i].canSee == '1') {
            that.jxcRoleList[7] = true
          }
          if (result[i].tableId == 9 && result[i].canSee == '1') {
            that.jxcRoleList[8] = true
          }
          if (result[i].tableId == 10 && result[i].canSee == '1') {
            that.jxcRoleList[9] = true
          }
          if (result[i].tableId == 11 && result[i].canSee == '1') {
            that.jxcRoleList[10] = true
          }
          if (result[i].tableId == 12 && result[i].canSee == '1') {
            that.jxcRoleList[11] = true
          }
          if (result[i].tableId == 13 && result[i].canSee == '1') {
            that.jxcRoleList[12] = true
          }
          if (result[i].tableId == 14 && result[i].canSee == '1') {
            that.jxcRoleList[13] = true
          }
          if (result[i].tableId == 15 && result[i].canSee == '1') {
            that.jxcRoleList[14] = true
          }
          if (result[i].tableId == 16 && result[i].canSee == '1') {
            that.jxcRoleList[15] = true
          }
          if (result[i].tableId == 17 && result[i].canSee == '1') {
            that.jxcRoleList[16] = true
          }
          if (result[i].tableId == 18 && result[i].canSee == '1') {
            that.jxcRoleList[17] = true
          }
          if (result[i].tableId == 19 && result[i].canSee == '1') {
            that.jxcRoleList[18] = true
          }
          if (result[i].tableId == 20 && result[i].canSee == '1') {
            that.jxcRoleList[19] = true
          }
          if (result[i].tableId == 21 && result[i].canSee == '1') {
            that.jxcRoleList[20] = true
          }
          if (result[i].tableId == 22 && result[i].canSee == '1') {
            that.jxcRoleList[21] = true
          }
          if (result[i].tableId == 23 && result[i].canSee == '1') {
            that.jxcRoleList[22] = true
          }
          if (result[i].tableId == 24 && result[i].canSee == '1') {
            that.jxcRoleList[23] = true
          }
          if (result[i].tableId == 25 && result[i].canSee == '1') {
            that.jxcRoleList[24] = true
          }
          if (result[i].tableId == 26 && result[i].canSee == '1') {
            that.jxcRoleList[25] = true
          }
          if (result[i].tableId == 27 && result[i].canSee == '1') {
            that.jxcRoleList[26] = true
          }
          if (result[i].tableId == 28 && result[i].canSee == '1') {
            that.jxcRoleList[27] = true
          }
          if (result[i].tableId == 29 && result[i].canSee == '1') {
            that.jxcRoleList[28] = true
          }
          if (result[i].tableId == 30 && result[i].canSee == '1') {
            that.jxcRoleList[29] = true
          }
          if (result[i].tableId == 31 && result[i].canSee == '1') {
            that.jxcRoleList[30] = true
          }
          if (result[i].tableId == 32 && result[i].canSee == '1') {
            that.jxcRoleList[31] = true
          }
          if (result[i].tableId == 33 && result[i].canSee == '1') {
            that.jxcRoleList[32] = true
          }
          if (result[i].tableId == 34 && result[i].canSee == '1') {
            that.jxcRoleList[33] = true
          }
          if (result[i].tableId == 35 && result[i].canSee == '1') {
            that.jxcRoleList[34] = true
          }
          if (result[i].tableId == 36 && result[i].canSee == '1') {
            that.jxcRoleList[35] = true
          }
          if (result[i].tableId == 37 && result[i].canSee == '1') {
            that.jxcRoleList[36] = true
          }
          if (result[i].tableId == 38 && result[i].canSee == '1') {
            that.jxcRoleList[37] = true
          }
        }
        that.isShowNum = true
        if (that.isShowNum) {
          that.getApproveNum()
          setInterval(function() {
            if (that.isShowNum) {
              that.dbnum = that.GLOBAL.approveNum
            }
          }, 1000)
        }
        that.$forceUpdate()
      }, (err) => {
        that.$alert('当前网络异常，请稍后重试', '提示')
      }, (end) => {
        that.loadFlag = false
      })
    },
    getApproveNum() {
      const that = this
      that.$request.doPost('/jxcApproveInfo/getApproveNum', {}, (res) => {
        if (res.data.code != 0) {
          that.$alert(res.data.message, '提示')
          return
        }
        if (res.data.result) {
          this.GLOBAL.approveNum = res.data.result.totalNum || 0
          that.isDbnum = true
        } else {
          that.isDbnum = false
        }
      }, (err) => {
        that.$alert('当前网络异常，请稍后重试', '提示')
      }, (end) => {
        that.loadFlag = false
      })
    },
    // 获取相关验证配置信息
    getAgentConfig() {
      const that = this
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo
      const url = '/wechat/getJsConfig'
      // 该paramUrl 为你使用微信sdk-js相关接口的页面地址 该地址需要配置到应用后台的可信域名下
      const paramUrl = window.location.href.split('#')[0]
      // let paramUrl = 'http://core-sales-test.huankehudong.com';
      const params = {
        authcorpid: userInfo.authcorpid,
        configType: 2,
        url: paramUrl
      }
      console.log('params', params)
      that.$request.doPost(url, params, (rsp) => {
        var config = rsp.data.result
        console.log('config', config)
        window.agentConfigParams = {
          corpid: userInfo.authcorpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: userInfo.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.noncestr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: [], // 你要调用的sdk接口必填
          success: function(res) {
            // 回调
          },
          fail: function(res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        }
        if (that.$isZiJian != 1) {
          that.getOpenWork()
        }
      }, (err) => {
        // that.$alert("当前网络异常，请稍后重试", "提示");
      }, (end) => { })
    },
    getOpenWork() {
      (
        async() => {
          try {
            if (/MicroMessenger/i.test(navigator.userAgent)) {
              await config(window.configParams)
            }
            console.log('window.agentConfigParams', window.agentConfigParams)
            await agentConfig(window.agentConfigParams)
            /**
             * 绑定 document 上全部的 ww-open-data 元素
             */
            console.log('WWOpenData', WWOpenData)
            WWOpenData.bindAll(document.querySelectorAll('ww-open-data'))
          } catch (error) { }
          /**
           * 调用 wx.agentConfig
           *
           */
          async function agentConfig(config) {
            return new Promise((success, fail) => {
              console.log('success, fail', config, success, fail)
              wx.agentConfig({ ...config, success, fail })
            }).then(
              (res) => {
                return res
              },
              (error) => {
                console.log(error)
                throw error
              })
          }
        })()
    },
    openNew() {
      const res = this.$router.resolve({
        path: '/pc/financeIndex'
      })
      window.open(res.href, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import "~@/style/microapp";

#face {
  width: 100%;
  height: 100vh;
}

#face > .el-container {
  display: flex;
  justify-content: flex-start;
}

#face > .el-container > .el-container-left > .el-header {
  position: relative;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem !important;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--bordGray);
}

#face > .el-container > .el-container-left > .el-header > #faceLogo {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
}

#face > .el-container > .el-container-left > .el-header > #faceLogo > img {
  width: 20px;
  height: 20px;
}

#face > .el-container > .el-container-left > .el-header > #faceLogo > span {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
  color: var(--mainBlue);
  white-space: nowrap;
}

#face > .el-container > .el-container-left > .el-header > #faceFold {
  width: 30px;
  font-size: 20px;
  font-weight: normal;
  color: var(--fontGray);
  text-align: center;
  cursor: pointer;
}

#face > .el-container > .el-container-left > .el-footer {
  position: fixed;
  top: 0;
  right: 12px;
  z-index: 1;
  min-width: 70px;
  height: 2.5rem !important;
  padding: 0 !important;
}

#face > .el-container > .el-container-left > .el-footer > #faceHead {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
}

#face > .el-container > .el-container-left > .el-footer > #faceHead .el-dropDown-txt {
  width: 100px;
  height: 2.5rem;
  padding: 0 0 0 10px;
  overflow: hidden;
  line-height: 2.5rem;
  text-align: right;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}

#face > .el-container > .el-container-left > .el-footer > #faceHead .el-dropDown-txt-mini {
  width: 100px;
  height: 2.5rem;
  padding: 0 10px;
  overflow: hidden;
  line-height: 2.5rem;
  text-align: right;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
}

#face > .el-container > .el-container-left > .el-header .el-dropdown-link {
  font-weight: bold;
  color: var(--fontGray);
}

#face > .el-container > .el-container-left > .el-header .el-dropdown-selfdefine {
  cursor: pointer;
}

#face > .el-container {
  width: 100%;
  height: 100vh;
}

#face > .el-container > .el-container-left > .el-aside {
  height: calc(100% - 2.5rem);
  margin: 0 0 2.5rem;
  background-color: #fff;
}

#face .dbnum {
  padding: 0 5px !important;
  margin-left: 5px !important;
  color: #fff !important;
  background: #409eff !important;
  border-radius: 10px !important;
}

#face > .el-container > .el-container-left > .el-aside > .el-menu {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  border: none;
}

#face > .el-container > .el-container-left > .el-aside > .el-menu .el-menu-item-group__title {
  height: 0 !important;
  padding: 0 !important;
}

#face > .el-container > .el-main {
  min-width: 1000px;
  height: 100%;
  padding: 0;
}

#face > .el-container > .el-main > .el-breadcrumb {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid var(--bordGray);
}

#face > .el-container > .el-main > .el-breadcrumb > .el-breadcrumb__item > .el-breadcrumb__inner {
  font-weight: normal;
  color: var(--fontGray);
}

#face > .el-container > .el-main > .el-breadcrumb > .feeTip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.8rem;
  font-size: 14px;
  background-color: #eaf3ff;
  border-bottom: 1px solid var(--bordGray);
}

#face > .el-container > .el-main > .el-breadcrumb > .feeTip > .left > span {
  color: #f00;
}

#face > .el-container > .el-main > .el-breadcrumb > .feeTip > .right {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
}

#face > .el-container > .el-main > .el-breadcrumb > .feeTip > .right > .payText {
  height: 100%;
  line-height: 2.5rem;
  color: #3975c5;
  cursor: pointer;
}

#face > .el-container > .el-main > .el-breadcrumb > .feeTip > .right > .closeText {
  margin-left: 1.25rem;
  color: var(--fontGray);
  cursor: pointer;
}

#face > .el-container > .el-main > #faceContainer {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 2.5rem);
  padding: 0.65rem 0.65rem 0;
}

#face > .el-container > .el-main > #faceContainer > #faceBox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}

#face .item-icon {
  width: 18px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
}

#face .el-menu--collapse {
  > .el-menu-item > .el-tooltip,
  > .el-submenu > .el-submenu__title {
    text-align: center;
  }

  .item-icon {
    margin-right: 0;
  }
}
</style>

import { SALE_CLOUD_ID } from '@/utils/microapp-variables'
import $ from 'jquery'

export default {
  computed: {
    isInWujie() {
      return window.$wujie || false
    }
  },
  mounted() {
    const that = this
    window.$wujie?.bus.$on('pushPath', (path, projectId) => {
      if (String(projectId) === String(SALE_CLOUD_ID)) {
        this.onMicroAppSwitchRouter(path)
      }
    })
    window.$wujie?.bus.$on('loginWithToken', (token) => {
      this.doLoginInCreate(that, token)
    })
    // window.$wujie?.bus.$on('setAuthInfo', (info) => {
    //   this.onMicroAppSetAuthInfo(info)
    // })
  },
  methods: {
    // 微前端切换路由
    onMicroAppSwitchRouter(path) {
      this.$router.push({ path })
    },
    doLoginInCreate(that, token) {
      localStorage.setItem("vertical", "/pc/index");
      that.doLogin(token);
      that.token = token;
    },
    onMicroAppEmitMenuList() {
      const list = []
      const $meneList = Array.from($('.el-aside>.el-menu>li'))
      $meneList.forEach(item => {
        const $item = $(item)
        if ($item.hasClass('el-menu-item')) {
          const $children = $item.children('span')
          const title = $children.text()
          list.push({
            title
          })
        } else if ($item.hasClass('el-submenu')) {
          const $children = $item.children('.el-submenu__title').children('span')
          const title = $children.text()
          const pushItem = {
            title,
            children: []
          }
          const $childEle = $($item.children('.el-menu').children('.el-menu-item-group').children('ul')[0])
          const $childList = Array.from($childEle.children('li'))
          $childList.forEach(childItem => {
            const $childItem = $(childItem)
            const titleChild = $childItem.text().replace(/\s/g, '')
            pushItem.children.push({
              title: titleChild
            })
          })
          list.push(pushItem)
        }
      })
      // 有效在通知微前端刷新菜单
      if (Array.isArray(list) && list.length > 0) {
        window.$wujie?.bus?.$emit('refreshMenu', list)
      }
    }
  }  
}
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import xlsx from 'xlsx';
import request from './assets/js/request.js';
import cloudRequest from './assets/js/cloudRequest.js';
import util from './assets/js/util.js';
import uniData from './assets/js/uniData.js';
import reg from './assets/js/reg.js';
import echarts from 'echarts';
import global_ from './components/Global'
import VueCompositionAPI from '@vue/composition-api'
import { VueJsonp } from 'vue-jsonp'

import uploadImage from '@/mixins/uploadImage.js'

import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)

import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)
import subjectData from './assets/js/subjectData.js';
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
Vue.mixin(uploadImage)
Vue.prototype.$store = store;
Vue.prototype.$xlsx = xlsx;
Vue.prototype.$request = request;
Vue.prototype.$util = util;
Vue.prototype.$uniData = uniData;
Vue.prototype.$reg = reg;
Vue.prototype.$echarts = echarts;
Vue.prototype.GLOBAL = global_
Vue.prototype.$subjectData = subjectData;
Vue.prototype.$isZiJian=process.env.VUE_APP_IS_ZIJIAN;
Vue.prototype.$cloudRequest = cloudRequest;

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['ww-open-data']
Vue.use(ElementUI);
Vue.use(VueCompositionAPI)
Vue.use(VueJsonp)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

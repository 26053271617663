import storage from 'good-storage'
//Session
export function SessionPut(key, value) {
  storage.session.set(key, value)
  return 
}
export function SessionGet(key) {
  return storage.session.get(key)
   
}
export function SessionClear() {
   storage.session.clear()
  return
}
export function SessionRemove(key) {
   storage.session.remove(key)
  return
}
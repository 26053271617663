<template>
  <el-container id="account" v-loading="loadFlag">
    <el-container>
      <div class="el-container-left" :style="{ width: isCollapse ? '70px' : '200px' }">
        <el-header>
          <div id="accountLogo" :style="{
              width: isCollapse ? '1.875rem' : '200px',
              'padding-left': isCollapse ? '10px' : '1.5625rem',
            }">
            <img src="@/assets/img/logo.png" alt="" />
            <span style="margin-left: 0.625rem" v-show="!isCollapse">欢客云会计</span>
          </div>
          <div id="accountFold">
            <i class="el-icon-s-fold" v-show="!isCollapse" @click="isCollapse = true"></i>
            <i class="el-icon-s-unfold" v-show="isCollapse" @click="isCollapse = false"></i>
          </div>
        </el-header>
        <!-- :default-active="$route.path" -->
        <el-aside class="no-overflow" :width="isCollapse ? '70px' : '200px'">
          <el-menu mode="vertical" unique-opened router :default-active="vertical" :collapse="isCollapse" :collapse-transition="false" background-color="#01152A" text-color="#fff" active-text-color="#fff">
            <el-menu-item index="/pc/financeIndex">
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item v-if="asideList[0]" index="" @mouseenter.native="mouseoverF($event)">
              <i class="el-icon-bank-card"></i>
              <span slot="title">应收应付</span>
            </el-menu-item>
            <el-menu-item v-if="asideList[1]" index="" @mouseenter.native.stop="mouseoverF($event)">
              <i class="el-icon-coin"></i>
              <span slot="title">账务处理</span>
            </el-menu-item>
            <el-menu-item v-if="asideList[2]" index="" @mouseenter.native.stop="mouseoverF($event)">
              <i class="el-icon-coin"></i>
              <span slot="title">财务报表</span>
            </el-menu-item>
            <el-menu-item v-if="asideList[3]" index="" @mouseenter.native.stop="mouseoverF($event)">
              <i class="el-icon-tickets"></i>
              <span slot="title">基础资料</span>
            </el-menu-item>
          </el-menu>
          <hoverWindow ref="hoverWindow" @getSelUrl="getSelUrl" :breadLis="breadList"></hoverWindow>
        </el-aside>
        <el-footer>
          <div id="accountHead">
            <el-dropdown trigger="click">
              <div class="el-dropdown-link el-dropDown-txt" :title="userInfo.userName" v-if="!isCollapse">
                <span v-if="userInfo && !userInfo.userId">{{
                  userInfo.userName
                }}</span>
                <openData v-if="userInfo && userInfo.userId" type="userName" :openid="userInfo.userId" :userName="userInfo.userName"></openData>
              </div>
              <div class="el-dropdown-link el-dropDown-txt-mini" :title="userInfo.userName" v-if="isCollapse">
                <span v-if="userInfo && !userInfo.userId">{{
                  userInfo.userName
                }}</span>
                <openData v-if="userInfo && userInfo.userId" type="userName" :openid="userInfo.userId" :userName="userInfo.userName"></openData>
              </div>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item @click.native="signOut"
                  >退出</el-dropdown-item
                > -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-footer>
      </div>
      <el-main @mouseenter.native.stop="mouseoutF()">
        <el-breadcrumb separator="|">
          <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" :to="item.fullPath">
            <span :class="item.selected ? 'topTitleC topTitleC-1' : 'topTitleC-1'">{{ item.name
              }}<i class="el-icon-close" @click.stop="deletePage(item)"></i></span>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <div id="faceContainer" ref="aa">
          <div id="faceBox" class="no-overflow">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import hoverWindow from "@/views/financial/components/hoverWindow";
import { SessionRemove } from "../views/financial/utils/cache.js";
export default {
  components: {
    hoverWindow,
  },
  name: "account",
  data() {
    return {
      asideList: [false, false, false],
      vertical: "/pc/financeIndex",
      loadFlag: false,
      isCollapse: false,
      userInfo: {},
      breadList: [],
      clientY: 0,
      hoverWindowList: [
        {
          innerText: "账务处理",
          list: [
            {
              title: "凭证",
              value: [
                { name: "录凭证", path: "/pc/addCertificate", isShow: false },
                { name: "查凭证", path: "/pc/certificateList", isShow: false },
                {
                  name: "凭证汇总表",
                  path: "/pc/voucherSummary",
                  isShow: false,
                },
              ],
              isShow: false,
            },
            {
              title: "账簿",
              value: [
                { name: "明细账", path: "/pc/subLedger", isShow: false },
                {
                  name: "科目余额表",
                  path: "/pc/subjectBalanceSheet",
                  isShow: false,
                },
                { name: "总分类账", path: "/pc/generalLedger", isShow: false },
              ],
              isShow: false,
            },
            {
              title: "智能凭证中心",
              value: [
                { name: "生成凭证", path: "", isShow: false },
                {
                  name: "科目关联设置",
                  path: "/pc/subjectAssociationSetting",
                  isShow: false,
                },
                {
                  name: "业务凭证模板",
                  path: "/pc/certificateGuide",
                  isShow: false,
                },
              ],
              isShow: false,
            },
            {
              title: "期末处理",
              value: [
                {
                  name: "期末结转",
                  path: "/pc/closingCarryForward",
                  isShow: false,
                },
                { name: "结账", path: "/pc/checkOut", isShow: false },
              ],
              isShow: false,
            },
          ],
        },
        {
          innerText: "财务报表",
          list: [
            {
              title: "报表",
              value: [
                { name: "资产负债表", path: "/pc/balanceSheet", isShow: false },
                { name: "利润表", path: "/pc/profitSheet", isShow: false },
                {
                  name: "现金流量表",
                  path: "/pc/cashFlowSheet",
                  isShow: false,
                },
                {
                  name: "项目利润表",
                  path: "/pc/projectProfitSheet",
                  isShow: false,
                },
              ],
              isShow: false,
            },
          ],
        },
        {
          innerText: "应收应付",
          list: [
            {
              title: "应收单据",
              value: [
                { name: "收款单", path: "/pc/collectionOrder", isShow: false },
                { name: "其他收入", path: "", isShow: false },
              ],
              isShow: false,
            },
            {
              title: "应付单据",
              value: [
                { name: "付款单", path: "/pc/paymentOrderList", isShow: false },
              ],
              isShow: false,
            },
            {
              title: "往来报表",
              value: [
                {
                  name: "卖场对账单",
                  path: "/pc/customerStatement",
                  isShow: false,
                },
                {
                  name: "供应商对账单",
                  path: "/pc/bankStatement",
                  isShow: false,
                },
              ],
              isShow: false,
            },
          ],
        },
        {
          innerText: "基础资料",
          list: [
            {
              title: "业务资料",
              value: [
                { name: "结算单位", path: "/pc/settlementUnit", isShow: false },
              ],
              isShow: false,
            },
            {
              title: "财务资料",
              value: [
                { name: "币别", path: "", isShow: false },
                {
                  name: "结算方式",
                  path: "/pc/settlementMethod",
                  isShow: false,
                },
                {
                  name: "账户管理",
                  path: "/pc/accountManagement",
                  isShow: false,
                },
                {
                  name: "收款单位管理",
                  path: "/pc/collectionUnit",
                  isShow: false,
                },
                { name: "科目", path: "/pc/subjectList", isShow: false },
                { name: "凭证字", path: "", isShow: false },
                {
                  name: "凭证模板",
                  path: "/pc/voucherTemplate",
                  isShow: false,
                },
              ],
              isShow: false,
            },
            {
              title: "期初录入",
              value: [
                {
                  name: "财务初始余额",
                  path: "/pc/caiWuInitialData",
                  isShow: false,
                },
                {
                  name: "现金流量初始数据",
                  path: "/pc/cashFlowInitialData",
                  isShow: false,
                },
                {
                  name: "账户初始数据",
                  path: "/pc/accountInitialData",
                  isShow: false,
                },
              ],
              isShow: false,
            },
          ],
        },
      ],
      jxcRoleList: [
        false, //收款单 tableid=101
        false, //其他收入 tableid=102
        false, //付款单 tableid=103
        false, //卖场对账单 tableid=104
        false, //供应商对账单 tableid=105
        false, //录凭证 tableid=106
        false, //查凭证 tableid=107
        false, //凭证汇总表 tableid=108
        false, //明细账 tableid=109
        false, //科目余额表 tableid=110
        false, //总分类账 tableid=111
        false, //生成凭证 tableid=111+1
        false, //科目关联设置 tableid=112+1
        false, //业务凭证模板  tableid=113+1
        false, //期末结转 tableid=114+1
        false, //结账 tableid=115+1
        false, //资产负债表 tableid=116+1
        false, //利润表 tableid=117+1
        false, //现金流量表 tableid=118+1
        false, //项目利润表 tableid=119+1
        false, //结算单位 tableid=120+1
        false, //币别 tableid=121+1
        false, //结算方式 tableid=122+1
        false, //账户管理 tableid=123+1
        false, //收款单位管理 tableid=124+1
        false, //科目 tableid=125+1
        false, //凭证字 tableid=126+1
        false, //凭证模板 tableid=127+1
        false, //财务初始余额 tableid=128+1
        false, //现金流量初始数据 tableid=129+1
        false, //账户初始数据 tableid=130+1
      ],
      loadFlag: false,
    };
  },
  watch: {
    $route: function (newVal, oldVal) {
      this.vertical = newVal.path;
      this.makeBread(newVal, oldVal);
    },
  },
  created() {
    document.title = "欢客销售财务系统";
    this.vertical = this.$route.path;
    this.breadList =
      JSON.parse(localStorage.getItem("breadList")) == null
        ? []
        : JSON.parse(localStorage.getItem("breadList"));
    this.getJxcRoleCanSee();
    return;
    that.makeBread(that.$route);
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to, from);
    next((vm) => { });
  },

  methods: {
    getJxcRoleCanSee() {
      let that = this;
      that.loadFlag = true;
      that.$request.doPost(
        "/tjxcroleinfo/getUserJxcRoleCansee",
        {},
        (res) => {
          if (res.data.code != 0) {
            that.$alert(res.data.message, "提示");
            return;
          }
          let result = res.data.result == null ? [] : res.data.result;

          // jxcRoleList console.log(result);
          for (let i = 24; i < result.length; i++) {
            if (result[i].tableId == 101 && result[i].canSee == "1") {
              that.jxcRoleList[0] = true;
              that.asideList[0] = true;
              that.hoverWindowList[2].list[0].isShow = true;
              that.hoverWindowList[2].list[0].value[0].isShow = true; //收款单
            }
            if (result[i].tableId == 102 && result[i].canSee == "1") {
              that.jxcRoleList[1] = true;
              that.asideList[0] = true;
              that.hoverWindowList[2].list[0].isShow = true;
              that.hoverWindowList[2].list[0].value[1].isShow = true; //其他收入
            }
            if (result[i].tableId == 103 && result[i].canSee == "1") {
              that.jxcRoleList[2] = true;
              that.asideList[0] = true;
              that.hoverWindowList[2].list[1].isShow = true;
              that.hoverWindowList[2].list[1].value[0].isShow = true; //付款单
            }
            if (result[i].tableId == 104 && result[i].canSee == "1") {
              that.jxcRoleList[3] = true;
              that.asideList[0] = true;
              that.hoverWindowList[2].list[2].isShow = true;
              that.hoverWindowList[2].list[2].value[0].isShow = true; //卖场对账单
            }
            if (result[i].tableId == 105 && result[i].canSee == "1") {
              that.jxcRoleList[4] = true;
              that.asideList[0] = true;
              that.hoverWindowList[2].list[2].isShow = true;
              that.hoverWindowList[2].list[2].value[1].isShow = true; //供应商对账单
            }
            if (result[i].tableId == 106 && result[i].canSee == "1") {
              that.jxcRoleList[5] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[0].isShow = true;
              that.hoverWindowList[0].list[0].value[0].isShow = true; //录凭证
            }
            if (result[i].tableId == 107 && result[i].canSee == "1") {
              that.jxcRoleList[6] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[0].isShow = true;
              that.hoverWindowList[0].list[0].value[1].isShow = true; //查凭证
            }
            if (result[i].tableId == 108 && result[i].canSee == "1") {
              that.jxcRoleList[7] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[0].isShow = true;
              that.hoverWindowList[0].list[0].value[2].isShow = true; //凭证汇总表
            }
            if (result[i].tableId == 109 && result[i].canSee == "1") {
              that.jxcRoleList[8] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[1].isShow = true;
              that.hoverWindowList[0].list[1].value[0].isShow = true; //明细账
            }
            if (result[i].tableId == 110 && result[i].canSee == "1") {
              that.jxcRoleList[9] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[1].isShow = true;
              that.hoverWindowList[0].list[1].value[1].isShow = true; //科目余额表
            }
            if (result[i].tableId == 111 && result[i].canSee == "1") {
              that.jxcRoleList[10] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[1].isShow = true;
              that.hoverWindowList[0].list[1].value[2].isShow = true; //总分类账
            }
            if (result[i].tableId == 112 && result[i].canSee == "1") {
              that.jxcRoleList[11] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[2].isShow = true;
              that.hoverWindowList[0].list[2].value[0].isShow = true; //生成凭证
            }
            if (result[i].tableId == 113 && result[i].canSee == "1") {
              that.jxcRoleList[12] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[2].isShow = true;
              that.hoverWindowList[0].list[2].value[1].isShow = true; //科目关联设置
            }
            if (result[i].tableId == 114 && result[i].canSee == "1") {
              that.jxcRoleList[13] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[2].isShow = true;
              that.hoverWindowList[0].list[2].value[2].isShow = true; //业务凭证模板
            }
            if (result[i].tableId == 115 && result[i].canSee == "1") {
              that.jxcRoleList[14] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[3].isShow = true;
              that.hoverWindowList[0].list[3].value[0].isShow = true; //期末结转
            }
            if (result[i].tableId == 116 && result[i].canSee == "1") {
              that.jxcRoleList[15] = true;
              that.asideList[1] = true;
              that.hoverWindowList[0].list[3].isShow = true;
              that.hoverWindowList[0].list[3].value[1].isShow = true; //结账
            }
            if (result[i].tableId == 117 && result[i].canSee == "1") {
              that.jxcRoleList[16] = true;
              that.asideList[2] = true;
              that.hoverWindowList[1].list[0].isShow = true;
              that.hoverWindowList[1].list[0].value[0].isShow = true; //资产负债表
            }
            if (result[i].tableId == 118 && result[i].canSee == "1") {
              that.jxcRoleList[17] = true;
              that.asideList[2] = true;
              that.hoverWindowList[1].list[0].isShow = true;
              that.hoverWindowList[1].list[0].value[1].isShow = true; //利润表
            }
            if (result[i].tableId == 119 && result[i].canSee == "1") {
              that.jxcRoleList[18] = true;
              that.asideList[2] = true;
              that.hoverWindowList[1].list[0].isShow = true;
              that.hoverWindowList[1].list[0].value[2].isShow = true; //现金流量表
            }
            if (result[i].tableId == 120 && result[i].canSee == "1") {
              that.jxcRoleList[19] = true;
              that.asideList[2] = true;
              that.hoverWindowList[1].list[0].isShow = true;
              that.hoverWindowList[1].list[0].value[3].isShow = true; //项目利润表
            }
            if (result[i].tableId == 121 && result[i].canSee == "1") {
              that.jxcRoleList[20] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[0].isShow = true;
              that.hoverWindowList[3].list[0].value[0].isShow = true; //结算单位
            }
            if (result[i].tableId == 122 && result[i].canSee == "1") {
              that.jxcRoleList[21] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[0].isShow = true; //币别
            }
            if (result[i].tableId == 123 && result[i].canSee == "1") {
              that.jxcRoleList[22] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[1].isShow = true; //结算方式
            }
            if (result[i].tableId == 124 && result[i].canSee == "1") {
              that.jxcRoleList[23] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[2].isShow = true; //账户管理
            }
            if (result[i].tableId == 125 && result[i].canSee == "1") {
              that.jxcRoleList[24] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[3].isShow = true; //收款单位管理
            }
            if (result[i].tableId == 126 && result[i].canSee == "1") {
              that.jxcRoleList[25] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[4].isShow = true; //科目
            }
            if (result[i].tableId == 127 && result[i].canSee == "1") {
              that.jxcRoleList[26] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[5].isShow = true; //凭证字
            }
            if (result[i].tableId == 128 && result[i].canSee == "1") {
              that.jxcRoleList[27] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[1].isShow = true;
              that.hoverWindowList[3].list[1].value[6].isShow = true; //凭证模板
            }
            if (result[i].tableId == 129 && result[i].canSee == "1") {
              that.jxcRoleList[28] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[2].isShow = true;
              that.hoverWindowList[3].list[2].value[0].isShow = true; //财务初始余额
            }
            if (result[i].tableId == 130 && result[i].canSee == "1") {
              that.jxcRoleList[29] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[2].isShow = true;
              that.hoverWindowList[3].list[2].value[1].isShow = true; //现金流量初始数据
            }
            if (result[i].tableId == 131 && result[i].canSee == "1") {
              that.jxcRoleList[30] = true;
              that.asideList[3] = true;
              that.hoverWindowList[3].list[2].isShow = true;
              that.hoverWindowList[3].list[2].value[2].isShow = true; //账户初始数据
            }
          }
          //console.log(that.hoverWindowList);
          that.$forceUpdate();
        },
        (err) => {
          that.$alert("数据异常", "提示");
        },
        (end) => {
          that.loadFlag = false;
        }
      );
    },
    getSelUrl(val) {
      this.breadList = val;
      localStorage.setItem("breadList", JSON.stringify(this.breadList));
    },
    mouseoverF(event) {
      this.clientY = parseInt(event.clientY) - 30;
      this.hoverWindowList.forEach((item) => {
        if (item.innerText == event.target.innerText) {
          this.$refs.hoverWindow.show(this.clientY, item.list);
        }
      });
    },
    mouseoutF() {
      this.$refs.hoverWindow.cancel();
    },
    makeBread(newVal, oldVal) {
      let path = newVal.path;
      let fullPath = newVal.fullPath;
      if (this.breadList.length > 0) {
        let isOne = true;
        this.breadList.forEach((item) => {
          item.selected = false;
          if (item.path == path) {
            isOne = false;
          }
        });
        if (isOne) {
          if (path == "/pc/financeIndex") {
            this.breadList.push({
              name: "首页",
              path: "/pc/financeIndex",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/addCertificate") {
            this.breadList.push({
              name: "录凭证",
              path: "/pc/addCertificate",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateList") {
            this.breadList.push({
              name: "查凭证",
              path: "/pc/certificateList",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateDetail") {
            this.breadList.push({
              name: "凭证详情",
              path: "/pc/certificateDetail",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateGuide") {
            this.breadList.push({
              name: "业务凭证模板",
              path: "/pc/certificateGuide",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/subjectList") {
            this.breadList.push({
              name: "科目",
              path: "/pc/subjectList",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/bankStatement") {
            this.breadList.push({
              name: "供应商对账单",
              path: "/pc/bankStatement",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/paymentOrderList") {
            this.breadList.push({
              name: "付款单",
              path: "/pc/paymentOrderList",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/addPaymentOrder") {
            this.breadList.push({
              name: "新增付款单",
              path: "/pc/addPaymentOrder",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/paymentOrderDetail") {
            this.breadList.push({
              name: "付款单详情",
              path: "/pc/paymentOrderDetail",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/collectionOrder") {
            this.breadList.push({
              name: "收款单",
              path: "/pc/collectionOrder",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/addCollectOrder") {
            this.breadList.push({
              name: "新增收款单",
              path: "/pc/addCollectOrder",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/collectionOrderDetail") {
            this.breadList.push({
              name: "收款单详情",
              path: "/pc/collectionOrderDetail",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/customerStatement") {
            this.breadList.push({
              name: "卖场对账单",
              path: "/pc/customerStatement",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/bankStatement") {
            this.breadList.push({
              name: "供应商对账单",
              path: "/pc/bankStatement",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/collectionUnit") {
            this.breadList.push({
              name: "收款单位管理",
              path: "/pc/collectionUnit",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/subjectAssociationSetting") {
            this.breadList.push({
              name: "科目关联设置",
              path: "/pc/subjectAssociationSetting",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/subjectMyAccount") {
            this.breadList.push({
              name: "科目关联设置-账户管理",
              path: "/pc/subjectMyAccount",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/supplierManagement") {
            this.breadList.push({
              name: "科目关联设置-供应商管理",
              path: "/pc/supplierManagement",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/settlementMethod") {
            this.breadList.push({
              name: "结算方式",
              path: "/pc/settlementMethod",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/accountManagement") {
            this.breadList.push({
              name: "账户管理",
              path: "/pc/accountManagement",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/collectionUnitSetting") {
            this.breadList.push({
              name: "科目关联设置-收款单位",
              path: "/pc/collectionUnitSetting",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/voucherTemplate") {
            this.breadList.push({
              name: "凭证模板",
              path: "/pc/voucherTemplate",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateGuideDetail") {
            this.breadList.push({
              name: "业务凭证模板详情",
              path: "/pc/certificateGuideDetail",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateGuideAdd") {
            this.breadList.push({
              name: "新增业务凭证模板",
              path: "/pc/certificateGuideAdd",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/caiWuInitialData") {
            this.breadList.push({
              name: "财务初始余额",
              path: "/pc/caiWuInitialData",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/certificateGuideAdd") {
            this.breadList.push({
              name: "现金流量初始数据",
              path: "/pc/certificateGuideAdd",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/accountInitialData") {
            this.breadList.push({
              name: "账户初始数据",
              path: "/pc/accountInitialData",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/closingCarryForward") {
            this.breadList.push({
              name: "期末结转",
              path: "/pc/closingCarryForward",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/balanceSheet") {
            this.breadList.push({
              name: "资产负债表",
              path: "/pc/balanceSheet",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/profitSheet") {
            this.breadList.push({
              name: "利润表",
              path: "/pc/profitSheet",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/cashFlowSheet") {
            this.breadList.push({
              name: "现金流量表",
              path: "/pc/cashFlowSheet",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/checkOut") {
            this.breadList.push({
              name: "结账",
              path: "/pc/checkOut",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/checkOutDet") {
            this.breadList.push({
              name: "结账前检查明细",
              path: "/pc/checkOutDet",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/collateVoucher") {
            this.breadList.push({
              name: "整理凭证",
              path: "/pc/collateVoucher",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/voucherSummary") {
            this.breadList.push({
              name: "凭证汇总表",
              path: "/pc/voucherSummary",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/subLedger") {
            this.breadList.push({
              name: "明细账",
              path: "/pc/subLedger",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/subjectBalanceSheet") {
            this.breadList.push({
              name: "科目余额表",
              path: "/pc/subjectBalanceSheet",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/generalLedger") {
            this.breadList.push({
              name: "总分类账",
              path: "/pc/generalLedger",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/settlementUnit") {
            this.breadList.push({
              name: "结算单位",
              path: "/pc/settlementUnit",
              fullPath: fullPath,
              selected: true,
            });
          } else if (path == "/pc/projectProfitSheet") {
            this.breadList.push({
              name: "项目利润表",
              path: "/pc/projectProfitSheet",
              fullPath: fullPath,
              selected: true,
            });
          }
        } else {
          this.breadList.forEach((item) => {
            item.selected = false;
            if (item.path == path) {
              item.selected = true;
              item.fullPath = fullPath;
            }
          });
        }
      } else {
        if (path == "/pc/financeIndex") {
          this.breadList.push({
            name: "首页",
            path: "/pc/financeIndex",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/addCertificate") {
          this.breadList.push({
            name: "录凭证",
            path: "/pc/addCertificate",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateList") {
          this.breadList.push({
            name: "查凭证",
            path: "/pc/certificateList",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateDetail") {
          this.breadList.push({
            name: "凭证详情",
            path: "/pc/certificateDetail",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateGuide") {
          this.breadList.push({
            name: "业务凭证模板",
            path: "/pc/certificateGuide",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/subjectList") {
          this.breadList.push({
            name: "科目",
            path: "/pc/subjectList",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/bankStatement") {
          this.breadList.push({
            name: "供应商对账单",
            path: "/pc/bankStatement",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/paymentOrderList") {
          this.breadList.push({
            name: "付款单",
            path: "/pc/paymentOrderList",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/addPaymentOrder") {
          this.breadList.push({
            name: "新增付款单",
            path: "/pc/addPaymentOrder",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/paymentOrderDetail") {
          this.breadList.push({
            name: "付款单详情",
            path: "/pc/paymentOrderDetail",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/collectionOrder") {
          this.breadList.push({
            name: "收款单",
            path: "/pc/collectionOrder",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/addCollectOrder") {
          this.breadList.push({
            name: "新增收款单",
            path: "/pc/addCollectOrder",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/collectionOrderDetail") {
          this.breadList.push({
            name: "收款单详情",
            path: "/pc/collectionOrderDetail",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/customerStatement") {
          this.breadList.push({
            name: "卖场对账单",
            path: "/pc/customerStatement",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/bankStatement") {
          this.breadList.push({
            name: "供应商对账单",
            path: "/pc/bankStatement",
            selected: true,
          });
        } else if (path == "/pc/collectionUnit") {
          this.breadList.push({
            name: "收款单位管理",
            path: "/pc/collectionUnit",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/subjectAssociationSetting") {
          this.breadList.push({
            name: "科目关联设置",
            path: "/pc/subjectAssociationSetting",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/subjectMyAccount") {
          this.breadList.push({
            name: "科目关联设置-账户管理",
            path: "/pc/subjectMyAccount",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/supplierManagement") {
          this.breadList.push({
            name: "科目关联设置-供应商管理",
            path: "/pc/supplierManagement",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/settlementMethod") {
          this.breadList.push({
            name: "结算方式",
            path: "/pc/settlementMethod",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/accountManagement") {
          this.breadList.push({
            name: "账户管理",
            path: "/pc/accountManagement",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/collectionUnitSetting") {
          this.breadList.push({
            name: "科目关联设置-收款单位",
            path: "/pc/collectionUnitSetting",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/voucherTemplate") {
          this.breadList.push({
            name: "凭证模板",
            path: "/pc/voucherTemplate",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateGuideDetail") {
          this.breadList.push({
            name: "业务凭证模板详情",
            path: "/pc/certificateGuideDetail",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateGuideAdd") {
          this.breadList.push({
            name: "新增业务凭证模板",
            path: "/pc/certificateGuideAdd",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/caiWuInitialData") {
          this.breadList.push({
            name: "财务初始余额",
            path: "/pc/caiWuInitialData",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/certificateGuideAdd") {
          this.breadList.push({
            name: "现金流量初始数据",
            path: "/pc/certificateGuideAdd",
            selected: true,
            fullPath: fullPath,
          });
        } else if (path == "/pc/accountInitialData") {
          this.breadList.push({
            name: "账户初始数据",
            path: "/pc/accountInitialData",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/closingCarryForward") {
          this.breadList.push({
            name: "期末结转",
            path: "/pc/closingCarryForward",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/balanceSheet") {
          this.breadList.push({
            name: "资产负债表",
            path: "/pc/balanceSheet",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/profitSheet") {
          this.breadList.push({
            name: "利润表",
            path: "/pc/profitSheet",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/cashFlowSheet") {
          this.breadList.push({
            name: "现金流量表",
            path: "/pc/cashFlowSheet",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/checkOut") {
          this.breadList.push({
            name: "结账",
            path: "/pc/checkOut",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/checkOutDet") {
          this.breadList.push({
            name: "结账前检查明细",
            path: "/pc/checkOutDet",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/collateVoucher") {
          this.breadList.push({
            name: "整理凭证",
            path: "/pc/collateVoucher",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/voucherSummary") {
          this.breadList.push({
            name: "凭证汇总表",
            path: "/pc/voucherSummary",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/subLedger") {
          this.breadList.push({
            name: "明细账",
            path: "/pc/subLedger",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/subjectBalanceSheet") {
          this.breadList.push({
            name: "科目余额表",
            path: "/pc/subjectBalanceSheet",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/generalLedger") {
          this.breadList.push({
            name: "总分类账",
            path: "/pc/generalLedger",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/settlementUnit") {
          this.breadList.push({
            name: "结算单位",
            path: "/pc/settlementUnit",
            fullPath: fullPath,
            selected: true,
          });
        } else if (path == "/pc/projectProfitSheet") {
          this.breadList.push({
            name: "项目利润表",
            path: "/pc/projectProfitSheet",
            fullPath: fullPath,
            selected: true,
          });
        }
      }
      localStorage.setItem("breadList", JSON.stringify(this.breadList));
    },
    deletePage(item) {
      // console.log(item);
      //当前删除nav索引
      let index = this.breadList.findIndex((aa) => aa.path == item.path);
      this.breadList.splice(index, 1);
      localStorage.setItem("breadList", JSON.stringify(this.breadList));
      if (this.breadList.length == 0) {
        this.$router.push({
          path: "/pc/financeIndex",
        });
      } else {
        //删除的刚好是最后一个nav 跳前面一个nav
        if (index == this.breadList.length) {
          this.$router.push({
            path: this.breadList[this.breadList.length - 1].fullPath,
          });
        }
        //删除的不是最后一个nav 跳下一个nav
        else {
          this.$router.push({
            path: this.breadList[index].fullPath,
          });
        }
      }
      // console.log(item.path)
      SessionRemove(item.path);
      if (item.path == "/pc/addCertificate") {
        this.$nextTick(() => {
          let vnode =
            this.$vnode.componentInstance.$parent.$parent.$children[0]
              .$children[0].$children[0].$children[0].$children[3].$children[1]
              .$vnode;
          let parentVonde = vnode.parent;
          if (
            parentVonde &&
            parentVonde.componentInstance &&
            parentVonde.componentInstance.cache
          ) {
            var cache = parentVonde.componentInstance.cache;
            var keys = parentVonde.componentInstance.keys;
            let key = "";
            let bb = item.path.split("/")[item.path.split("/").length - 1];
            keys.forEach((ite) => {
              let aa =
                cache[ite].tag.split("-")[cache[ite].tag.split("-").length - 1];
              if (aa == bb) {
                key = ite;
              }
            });
            if (cache[key]) {
              //this.$destroy();
              if (keys.length) {
                var index = keys.indexOf(key);
                if (index > -1) {
                  keys.splice(index, 1);
                }
              }
              cache[key] = null;
            }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
#account {
  width: 100%;
  height: 100vh;
}

#account > .el-container {
  display: flex;
  justify-content: flex-start;
}

#account > .el-container > .el-container-left > .el-header {
  position: relative;
  top: 0;
  width: 100%;
  height: 4.5rem !important;
  background-color: #00213f;
  box-sizing: border-box;
  /* border-bottom: 1px solid var(--bordGray); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  z-index: 1;
}

#account > .el-container > .el-container-left > .el-header > #accountLogo {
  height: 100%;
  color: #fe733d;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

#account
  > .el-container
  > .el-container-left
  > .el-header
  > #accountLogo
  > img {
  width: 1.875rem;
  height: 1.875rem;
}

#account > .el-container > .el-container-left > .el-header > #accountFold {
  font-weight: normal;
  font-size: 20px;
  color: #fe733d;
  cursor: pointer;
  width: 30px;
  text-align: center;
}

#account > .el-container > .el-container-left > .el-footer {
  position: fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 2.5rem !important;
  padding: 0 !important;
  z-index: 1;
  /* border-bottom: 1px solid var(--bordGray); */
}

#account > .el-container > .el-container-left > .el-footer > #accountHead {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0;
}

#account
  > .el-container
  > .el-container-left
  > .el-footer
  > #accountHead
  .el-dropDown-txt {
  text-align: right;
  width: 100px;
  height: 2.5rem;
  line-height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
  padding: 0 10px;
}

#account
  > .el-container
  > .el-container-left
  > .el-footer
  > #accountHead
  .el-dropDown-txt-mini {
  text-align: right;
  width: 100px;
  height: 2.5rem;
  line-height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  cursor: pointer;
  padding: 0 10px;
}

#account > .el-container > .el-container-left > .el-header .el-dropdown-link {
  font-weight: bold;
  color: var(--fontGray);
}
#account
  > .el-container
  > .el-container-left
  > .el-header
  .el-dropdown-selfdefine {
  cursor: pointer;
}

#account > .el-container {
  width: 100%;
  height: 100vh;
}

#account > .el-container > .el-container-left > .el-aside {
  margin: 0 0 2.5rem 0;
  height: calc(100% - 4.5rem);
  background-color: #01152a;
}

#account > .el-container > .el-container-left > .el-aside > .el-menu {
  width: 100%;
  border: none;
  overflow-x: hidden;
  overflow-y: hidden;
}
#account
  > .el-container
  > .el-container-left
  > .el-aside
  > .el-menu
  >>> .el-menu-item:hover,
.el-menu-item:focus {
  background-color: #fe733d !important;
  /* margin: 0px 10px !important; */
  border-radius: 5px !important;
}
#account > .el-container > .el-container-left > .el-aside > .el-menu >>> i {
  color: #fff !important;
}
#account
  > .el-container
  > .el-container-left
  > .el-aside
  > .el-menu
  .el-menu-item-group__title {
  height: 0 !important;
  padding: 0 !important;
}

#account > .el-container > .el-main {
  min-width: 48.375rem;
  height: 100%;
  padding: 0;
}

#account > .el-container > .el-main > .el-breadcrumb {
  width: 100%;
  /*height: 2.5rem;*/
  box-sizing: border-box;
  padding: 0.2rem 0.75rem;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  border-bottom: 1px solid var(--bordGray);
  flex-wrap: wrap;
  line-height: 2rem;
}

#account
  > .el-container
  > .el-main
  > .el-breadcrumb
  > .el-breadcrumb__item
  > .el-breadcrumb__inner {
  color: var(--fontGray);
  font-weight: normal;
}
.topTitleC {
  color: #fe733d;
  background-color: #fff2ee;
}
.topTitleC-1 {
  padding: 3px 10px;
  margin-right: 2px;
}
.topTitleC-1:hover {
  cursor: pointer;
  color: #fe733d;
}
#account >>> .el-icon-close {
  margin-left: 10px;
}
#account >>> .el-icon-close:hover {
  color: #fe733d !important;
}
#account > .el-container > .el-main > #faceContainer {
  width: 100%;
  height: calc(100% - 2.5rem);
  box-sizing: border-box;
  padding: 0.65rem 0.65rem 0 0.65rem;
}

#account > .el-container > .el-main > #faceContainer > #faceBox {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  overflow-y: auto;
}
#account .item-icon {
  vertical-align: middle;
  margin-right: 10px;
  width: 18px;
  text-align: center;
}
.hoverC {
  position: absolute;
}
</style>

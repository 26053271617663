let subjectDataArr = () => {
    let arr = [
        {

            label: "全部",
            value: "",
            children: [

                {
                    value: 1,
                    label: "资产",
                    children: [{
                        value: 11, label: "流动资产"
                    },
                    {
                        value: 12, label: "非流动资产"
                    }]
                },
                {
                    value: 2,
                    label: "负债",
                    children: [{
                        value: 21, label: "流动负债"
                    },
                    {
                        value: 22, label: "非流动负债"
                    }]
                },
                {
                    value: 3,
                    label: "权益",
                    children: [{
                        value: 31, label: "所有者权益"
                    },
                    ]
                },
                {
                    value: 5,
                    label: "成本",
                    children: [{
                        value: 51, label: "成本"
                    },
                    ]
                },
                {
                    value: 4,
                    label: "损益",
                    children: [{
                        value: 41, label: "营业收入"
                    },
                    {
                        value: 42, label: "营业成本及税金"
                    },
                    {
                        value: 43, label: "期间费用"
                    },
                    {
                      value: 44, label: "其他收益"
                  },
                  {
                    value: 45, label: "其他损失"
                },
                {
                  value: 46, label: "所得税"
              },
              {
                value: 47, label: "以前年度损益调整"
            },
                    ]

                },
            ]
        },

    ]
    return arr;
}

module.exports = {
    subjectDataArr
}
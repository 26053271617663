<template>
  <span :class="boxName == 'goal' ? 'auto-overflow' : 'online-overflow'">
    <!-- <ww-open-data :type="type" :openid="openid" v-if="$isZiJian!=1&&userInfo.agentid!=-1"/>
    <span v-else>{{userName}}</span> -->
    <span>{{userName}}</span>
  </span>
</template>
<script>
export default {
  props: {
    // 类型：分为部门和人员姓名
    type: {
      type: String,
      default: undefined
    },
    // id:人员或部门的ID
    openid: {
      type: String,
      default: undefined
    },
    userName: {
      type: String,
      default: undefined
    },
    boxName: {
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      userInfo:{},
    }
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo")).userInfo;
    if(this.$isZiJian!=1&&this.userInfo.agentid!=-1){
      this.showData()
    }    
  },
  methods: {
    showData() {
      // 绑定ww-open-data组件，这样才能显示出姓名或部门名
      // eslint-disable-next-line no-undef
      // WWOpenData.bind(document.querySelector('ww-open-data'))
    }
  }
}
</script>

import axios from 'axios';
import Vue from 'vue';

let getCloudToken=()=>{
	return new Promise((resolve,reject)=>{
		let userInfo=sessionStorage.getItem('userInfo');		
		let token=userInfo&&JSON.parse(userInfo)?JSON.parse(userInfo).token:'';
		axios.post('/cloudApi/xsy/app/pre/user/getReceptionToken',{isNew:1,deviceType:'PC_WEB'},{
			headers:{
				'Content-Type':'application/json;chartset=uft-8',
				'Authorization':token,
			}
		}).then((res)=>{
			if(res.data.code!=200){
				sessionStorage.removeItem('cloudToken');
				Vue.prototype.$alert(res.data.message, '提示');
				return;
			}
			let cloudToken=JSON.parse(res.data.data).authToken.accessToken;
			cloudToken=cloudToken.replace(/"/g,'');
			sessionStorage.setItem('cloudToken',cloudToken);
			resolve();
		}).catch((err)=>{}).finally(()=>{});
	})
}

let cloudPost=async(oUrl,oData,ok,err,end)=>{
	ok=ok||'';
	err=err||'';
	end=end||'';
	if(!sessionStorage.getItem('cloudToken')){
		await getCloudToken();
	}
	let userInfo=sessionStorage.getItem('userInfo');		
	let token=userInfo&&JSON.parse(userInfo)?JSON.parse(userInfo).token:'';
	axios.post('/cloudApi'+oUrl,oData,{
		headers:{
			'Content-Type':'application/json;chartset=uft-8',
			'satoken':sessionStorage.getItem('cloudToken'),
			'Authorization':token,
		}
	}).then((res)=>{
		if (res.data.code!=200&&res.data.message&&res.data.message.includes('oken')){
			sessionStorage.removeItem('cloudToken');
		}
		ok(res);
	}).catch((e)=>{
		err(e);
	}).finally(()=>{
		end&&end();
	});
}

let cloudGet=async(oUrl,oData,ok,err,end)=>{
	ok=ok||'';
	err=err||'';
	end=end||'';
	if(!sessionStorage.getItem('cloudToken')){
		await getCloudToken();
	}
	let userInfo=sessionStorage.getItem('userInfo');		
	let token=userInfo&&JSON.parse(userInfo)?JSON.parse(userInfo).token:'';
	axios.get('/cloudApi'+oUrl,{
		params:oData,
		headers:{
			'Content-Type':'application/json;chartset=uft-8',
			'satoken':sessionStorage.getItem('cloudToken'),
			'Authorization':token,
		}
	}).then((res)=>{
		if (res.data.code!=200&&res.data.message&&res.data.message.includes('oken')){
			sessionStorage.removeItem('cloudToken');
		}
		ok(res);
	}).catch((e)=>{
		err(e);
	}).finally(()=>{
		end();
	});
}

let cloudExportOut=async(oUrl,oData,name,ok,err,end)=>{
	ok=ok||'';
	err=err||'';
	end=end||'';
	if(!sessionStorage.getItem('cloudToken')){
		await getCloudToken();
	}
	let userInfo=sessionStorage.getItem('userInfo');		
	let token=userInfo&&JSON.parse(userInfo)?JSON.parse(userInfo).token:'';
	axios({
		url:'/cloudApi' + oUrl, 
		method:'post',
		data: oData,
		headers:{
			'Content-Type':'application/json;chartset=uft-8',
			'satoken':sessionStorage.getItem('cloudToken'),
			'Authorization':token,
		},
		responseType: 'blob',
	}).then((res) => {
		let a = document.createElement('a')
		let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
		let href = window.URL.createObjectURL(blob) // 创建下载的链接
		a.href = href
		let date = new Date()
		let str = ""
		str += date.getFullYear()
		let month = date.getMonth() + 1
		if(month < 10){
			str += "0"+month
		}else{
			str += month
		}
		str += date.getDate()
		str += date.getHours()
		str += date.getMinutes()
		str += date.getSeconds()
		a.download = name + '_' + str + '.xls' // 下载后文件名
		document.body.appendChild(a)
		a.click() // 点击下载
		document.body.removeChild(a) // 下载完成移除元素
		window.URL.revokeObjectURL(href) // 释放掉blob对象
		ok();
	}).catch(() => {
		err();
	}).finally(() => {
		end();
	});
}

export default{
	cloudPost,
	cloudGet,
	cloudExportOut
}

import jquery from 'jquery'

export default {
  methods: {
    beforeUploadImage(file) {
      return new Promise((resolve, reject) => {
        this.$request.doPost('/oss/policy', {}, (res) => {
          const ossData = res.data
          // const fileData = file.file
          const fileData = file
          let fileType = fileData.name.split('.')[1]
          if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png') {
            fileType = 'jpg'
          }
          const formData = new FormData()
          formData.append('key', ossData.dir + ossData.file_name + '.' + fileType) // 存储在oss的文件路径
          formData.append('OSSAccessKeyId', ossData.accessid) // accessKeyId
          formData.append('policy', ossData.policy) // policy
          formData.append('signature', ossData.signature) // 签名
          formData.append('file', fileData.raw)
          formData.append('success_action_status', 200) // 成功后返回的操作码
          jquery.ajax({
            url: ossData.host,
            type: 'POST',
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            success: success => {
              const imagePath = ossData.host + '/' + ossData.dir + ossData.file_name + '.' + fileType
              resolve(imagePath)
            },
            error: () => {
              this.$alert('图片上传失败', '提示')
            }
          })
        }, () => {
          this.$alert('当前网络异常，请稍后重试', '提示')
        }, () => {
          this.loadFlag = false
        })
      })
    }
  }
}
